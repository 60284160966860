import { em, rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  margin: ${rem(16)} 0 0 0;
  color: ${({ theme }) => theme.breadcrumbs.textColor};
  font-size: ${em(14)};
  display: flex;
  flex-wrap: wrap;
  min-height: 1em;
`;

const Crumb = styled.span``;

const LinkCrumb = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.breadcrumbs.textColor};
`;

const Separator = styled.span`
  margin: 0 ${rem(4)};
`;

const BREADCRUMBS_SEPARATOR_CHARACTER = '/';

interface IOwnProps {
  className?: string;
  crumbs: Array<{
    label: string;
    url?: string;
  }>;
}

type Props = IOwnProps;

const C: React.FC<Props> = ({ crumbs, className }) => (
  <Wrapper className={className}>
    {crumbs.map((c, idx) => (
      <Crumb key={idx}>
        {c.url ? <LinkCrumb to={c.url}>{c.label}</LinkCrumb> : c.label}
        {idx + 1 < crumbs.length && (
          <Separator>{BREADCRUMBS_SEPARATOR_CHARACTER}</Separator>
        )}
      </Crumb>
    ))}
  </Wrapper>
);

export default C;
