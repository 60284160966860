import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Loading from '../../components/Loading';
import { ReduxConnectProps, RootState } from '../../configureStore';
import Article from '../Article';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';
import Section from '../Section';
import { loadByUrl } from './actions';
import {
  urlDetailIsFetchingSelector,
  urlDetailSelector,
  urlErrorSelector,
} from './selectors';
import Forbidden from '../Forbidden';
import Redirect from '../Redirect';

type RouterProps = RouteComponentProps<
  {
    id: string;
    appspace: string;
    splat: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps & ReduxConnectProps,
) => {
  const pathname = props.params.splat;
  return {
    isFetching: urlDetailIsFetchingSelector(pathname)(state),
    detail: urlDetailSelector(pathname)(state),
    error: urlErrorSelector(pathname)(state),
  };
};

type Props = RouterProps &
  ReduxConnectProps &
  ReturnType<typeof mapStateToProps>;

class UrlMap extends React.PureComponent<Props> {
  static async init(props: Props) {
    return props.dispatch(loadByUrl.action(props.params.splat));
  }

  static async getInitialProps(props: Props) {
    const response = await UrlMap.init(props);
    if (response.data.urltype === 'section') {
      await Section.getInitialProps({ ...props, id: response.data.object_id });
    } else if (response.data.urltype === 'article') {
      await Article.getInitialProps({ ...props, id: response.data.object_id });
    }
  }

  async componentDidMount() {
    try {
      await UrlMap.init(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await UrlMap.init(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  render() {
    const { isFetching, detail, error } = this.props;

    if (isFetching && detail === null) {
      return <Loading />;
    }

    if (detail) {
      if (detail.urltype === 'section') {
        return <Section id={detail.object_id} />;
      }

      if (detail.urltype === 'article') {
        return <Article id={detail.object_id} />;
      }
    }

    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }
    if (error && error.identifier === 'REDIRECT') {
      return <Redirect to={error.payload.location} />;
    }

    return <NotFound />;
  }
}

export default compose(connect(mapStateToProps))(UrlMap);
