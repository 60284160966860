import { emphasizeColor } from '@sportnet/ui/Themes/utilities';
import { darken, desaturate, lighten, rem, transparentize } from 'polished';
import config from '../config';
import { RecursivePartial } from '../library/App';
import getColor from '../utilities/getColor';

type Color = string;
type Size = number;
// type SizeString = string;
// type Shadow = string;
// type FontFamily = string;

export interface ITheme {
  color: {
    primary: Color;
    secondary: Color;
    // action
    danger: Color;
    warning: Color;
    success: Color;
    // background
    primaryBg: Color;
    secondaryBg: Color;
    pageBg: Color;
    // text
    text: Color;
    fadedText: Color;
    separator: Color;
    placeholder: Color;
    [key: string]: Color;
  };
  grid: {
    gutterWidth: Size;
  };
  breadcrumbs: {
    textColor: Color;
  };
  title: {
    textColor: Color;
  };
  contentMetaInformation: {
    textColor: Color;
  };
  author: {
    textColor: Color;
  };
  articleList: {
    bgColor: Color;
  };
  app: {
    fontName: string;
    maxWidth: number;
    padding: number;
    bgColor: Color;
    secondaryBgColor: Color;
    textColor: Color;
    secondaryTextColor: Color;
  };
  loader: {
    color: Color;
  };
  paginator: {
    backgroundColor: Color;
    hoverBackgroundColor: Color;
    activeColor: Color;
    disabledColor: Color;
    borderColor: Color;
    textColor: Color;
  };
  searchInput: {
    textColor: Color;
    placeholderColor: Color;
    separatorColor: Color;
    resetButtonColor: Color;
  };
  responsiveMenuPrimary: Color;
  responsiveMenu: {
    backgroundColor: Color;
    backdropColor: Color;
    linkColor: Color;
    linkColorHighlighted: Color;
    linkHoverColor: Color;
    borderRadius: string;
    siteUrlColor: Color;
    siteUrlBorder: Color;
    closeButtonColor: Color;
  };
}

export const getTheme = (override: RecursivePartial<ITheme> = {}): ITheme => {
  const color = {
    primary: '#5283e7',
    secondary: '#5283e7',
    // action
    danger: '#e94141',
    warning: '#E8AE2B',
    success: '#5bd084',
    // background
    primaryBg: '#FAFAFA',
    secondaryBg: '#5283e7',
    pageBg: '#fff',
    // text
    text: '#555555',
    fadedText: '#aaa',
    separator: '#eee',
    placeholder: '#ccc',
    backdrop: 'rgba(0, 0, 0, 0.25)',
  };

  // safely override colors
  // ensure all color strings are valid
  const overrideColors = override.color || {};
  Object.keys(overrideColors).forEach((key: keyof typeof color) => {
    if (key in color) {
      color[key] = getColor(overrideColors[key]!, color[key]); // ! because it cannot be possible undefined
    }
  });

  const app = {
    fontName: 'Ubuntu',
    maxWidth: config.MAX_WIDTH, // in future if this setting will be configurable please delete config.CONTENT_MAX_WIDTH and config.WIDER_CONTENT_MAX_WIDTH
    padding: 15,
    bgColor: '#fff',
    secondaryBgColor: '#FAFAFA',
    textColor: '#484848',
    secondaryTextColor: '#707070',
    ...override.app,
  };

  if (override.app) {
    app.bgColor = getColor(override.app.bgColor, app.bgColor);
    app.secondaryBgColor = getColor(
      override.app.secondaryBgColor,
      app.secondaryBgColor,
    );
    app.textColor = getColor(override.app.textColor, app.textColor);
    app.secondaryTextColor = getColor(
      override.app.secondaryTextColor,
      app.secondaryTextColor,
    );
  }

  const grid = {
    gutterWidth: 10,
  };

  const breadcrumbs = {
    textColor: app.secondaryTextColor,
    ...override.breadcrumbs,
  };

  const title = {
    textColor: emphasizeColor(0.12, app.textColor),
    ...override.title,
  };

  const contentMetaInformation = {
    textColor: app.secondaryTextColor,
    ...override.contentMetaInformation,
  };

  const author = {
    textColor: app.secondaryTextColor,
    ...override.author,
  };

  const articleList = {
    bgColor: app.secondaryBgColor,
    ...override.articleList,
  };

  const loader = {
    color: color.primary,
    ...override.author,
  };

  const paginator = {
    backgroundColor: app.bgColor,
    hoverBackgroundColor: darken(0.03, app.bgColor),
    activeColor: color.primary,
    disabledColor: app.secondaryTextColor,
    borderColor: emphasizeColor(0.1, app.bgColor, true),
    textColor: app.textColor,
    ...override.paginator,
  };

  const responsiveMenuPrimary = override.responsiveMenuPrimary || color.primary;

  const responsiveMenu = {
    backgroundColor: responsiveMenuPrimary,
    backdropColor: 'rgba(0, 0, 0, 0.25)',
    subsectionBackgroundColor: darken(0.03, responsiveMenuPrimary),
    linkColor: lighten(0.9, responsiveMenuPrimary),
    linkColorHighlighted: desaturate(0.1, lighten(0.5, responsiveMenuPrimary)),
    linkHoverColor: darken(0.02, responsiveMenuPrimary),
    borderRadius: rem(4),
    siteUrlColor: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)),
    siteUrlBorder: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)),
    closeButtonColor: '#fff',
    ...override.responsiveMenu,
  };

  const searchInput = {
    textColor: app.textColor,
    placeholderColor: transparentize(0.5, app.textColor),
    separatorColor: emphasizeColor(0.1, app.bgColor, true),
    resetButtonColor: app.textColor,
  };

  return {
    color,
    grid,
    breadcrumbs,
    contentMetaInformation,
    author,
    articleList,
    app,
    title,
    loader,
    paginator,
    searchInput,
    responsiveMenuPrimary,
    responsiveMenu,
  };
};

export const theme = getTheme();
