import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';
import at from '@sportnet/utilities/at';
import { RootState } from '../../configureStore';
import { SectionId } from '../../library/App';
import {
  detailDataSelector,
  detailTypeSelector,
  entitiesSelector,
} from '../../pages/App/selectors';
import { currentArticleSelector } from '../../pages/Article/selectors';
import { IDetailInitialState } from '../App/reducer';

// Current section id - that has been set by action
const currentSetSectionIdSelector = (state: RootState) =>
  state.currentSectionId;

export const currentSectionIdSelector = createSelector(
  currentSetSectionIdSelector,
  currentArticleSelector,
  (sectionId, article) => {
    if (article && article.sectionid) {
      if (Array.isArray(article.sectionid)) {
        return article.sectionid[0];
      }
      return article.sectionid;
    }
    return sectionId;
  },
);

// Section CONTENT
const currentSectionDomain = createSelector(
  currentSectionIdSelector,
  detailTypeSelector('sectionById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentSectionIsFetchingSelector = createSelector(
  currentSectionDomain,
  (domain) => !!domain.isFetching,
);

export const currentSectionErrorSelector = createSelector(
  currentSectionDomain,
  (domain) => domain.error,
);

export const currentSectionSelector = createSelector(
  entitiesSelector,
  currentSectionIdSelector,
  (entities, id) =>
    id !== null && id in entities.sections ? entities.sections[id] : null,
);

// Section ARTICLES

const sectionArticlesByIdDomainSelector = (state: RootState) =>
  state.sectionArticlesById;

const currentSectionArticlesByIdDomain = createSelector(
  sectionArticlesByIdDomainSelector,
  currentSectionIdSelector,
  (byId, id) => (id === null || !(id in byId) ? null : byId[id]),
);

const currentSectionArticleIdsSelector = createSelector(
  currentSectionArticlesByIdDomain,
  (domain) => {
    if (domain) {
      return domain.articles || [];
    }
    return [];
  },
);

export const currentSectionArticlesSelector = createSelector(
  currentSectionArticleIdsSelector,
  entitiesSelector,
  (ids, entities) => {
    return ids.map((id) => entities.articles[id]);
  },
);

export const currentSectionArticlesIsFetchingSelector = createSelector(
  currentSectionArticlesByIdDomain,
  (domain) => (domain ? domain.isFetching : false),
);

// Path
export const pathSelector = (sectionId: SectionId | null) =>
  createSelector(
    entitiesSelector,
    detailDataSelector('pathBySectionId', { id: String(sectionId) }),
    (entities, pathData) =>
      at(entities.sections, getProp(pathData, ['path'], []).slice(1)),
  );
