import {
  commit,
  CommitError,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import { getProp } from '@sportnet/utilities';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { Article, Doctype } from '../../api/CmsApi';
import config from '../../config';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { ApiError } from '../../library/Api';
import {
  ArticleId,
  IArticle,
  IPrivateContent,
  NormalizedEntities,
  Pager,
} from '../../library/App';
import { ALL_DOCTYPES } from '../../library/AppSettings';
import NotFoundError from '../../NotFoundError';
import { loadPath } from '../../pages/App/actions';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceListArticleProps from '../../utilities/reduceListArticleProps';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const normalizeArticles = (data: Article[]) => {
  return {
    entities: {
      articles: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: Article },
    },
    result: data.map((item) => item._id as ArticleId),
  };
};

export const loadArticle = createAsync<
  { id: ArticleId },
  NormalizedEntities<'articles'>
>('LOAD_ARTICLE', async ({ id }, dispatch, getState, { CmsApi }) => {
  const appSpace = CSMAppSpaceSelector(getState());

  let response: Article;
  let state: IPrivateContent['state'] = 'FULL';

  try {
    response = await CmsApi.getPublicArticlesById(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      id,
      {
        expandWidgets: true,
      },
    );
  } catch (e: any) {
    if (e && (e as ApiError).details && (e as ApiError).details?.code === 404) {
      throw new NotFoundError(e);
    } else if (
      e &&
      (e as ApiError).details &&
      (e as ApiError).details?.code === 401
    ) {
      const data = getProp((e as ApiError).details, ['payload', 'data'], null);
      if (data) {
        response = data as Article;
        state = 'UNATHORIZED';
      } else {
        throw new UnauthorizedError(e);
      }
    } else if (
      e &&
      (e as ApiError).details &&
      (e as ApiError).details?.code === 403
    ) {
      const data = getProp((e as ApiError).details, ['payload', 'data'], null);
      if (data) {
        response = data as Article;
        state = 'FORBIDDEN';
      } else {
        throw new ForbiddenError(e);
      }
    } else {
      throw new InternalServerError(e);
    }
  }

  const sectionId = Array.isArray(response.sectionid)
    ? response.sectionid[0]
    : response.sectionid;

  if (sectionId) {
    await dispatch(loadPath.action({ id: sectionId }));
  }

  return normalizeEntities('articles', [{ ...response, state }]);
});

// Set current article id
export const setCurrentArticleId = create<ArticleId | null>(
  'SET_CURRENT_ARTICLE_ID',
);

export const loadArticles = createAsync<
  {
    limit?: number;
    q?: string;
    page?: number;
    offset?: number;
    smarttags?: string[];
    doctypes?: string[];
    // articleId?: ArticleId;
    authorId?: string;
  },
  NormalizedEntities<'articles'> & Pager
>('LOAD_ARTICLES', async (parameters, dispatch, getState, { CmsApi }) => {
  const appSpace = CSMAppSpaceSelector(getState());
  const params: {
    offset: number;
    limit: number;
    q?: string;
    smarttags?: string[];
    authorId?: string;
    doctypes?: string[];
  } = {
    offset: 0,
    limit: 12,
  };
  if (parameters.limit || parameters.limit === 0) {
    params.limit = parameters.limit;
  }
  if (parameters.page && parameters.page > 1) {
    params.offset = (parameters.page - 1) * params.limit;
  }
  if (parameters.offset || parameters.offset === 0) {
    params.offset = parameters.offset;
  }
  if (parameters.q || parameters.q === '') {
    params.q = parameters.q;
  }
  if (parameters.smarttags && parameters.smarttags.length > 0) {
    params.smarttags = parameters.smarttags;
  }
  if (parameters.authorId) {
    params.authorId = parameters.authorId;
  }
  if (parameters.doctypes && parameters.doctypes.length > 0) {
    params.doctypes = parameters.doctypes;
  }
  const { articles, limit, offset, total, nextOffset } =
    await CmsApi.getPublicArticles(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      params,
    );

  return {
    ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
    limit: limit!,
    offset: offset!,
    total: total!,
    nextOffset: nextOffset || null,
  };
});

export const loadSimilarArticles = createAsync<
  {
    limit?: number;
    page?: number;
    offset?: number;
    articleId?: ArticleId;
  },
  NormalizedEntities<'articles'> & Pager
>(
  'LOAD_SIMILAR_ARTICLES',
  async (parameters, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());
    const params: {
      offset: number;
      limit: number;
    } = {
      offset: 0,
      limit: config.LIST_SIMILAR_ARTICLES_LIMIT,
    };
    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }
    if (parameters.page && parameters.page > 1) {
      params.offset = (parameters.page - 1) * params.limit;
    }
    if (parameters.offset || parameters.offset === 0) {
      params.offset = parameters.offset;
    }
    const { articles, limit, offset, total, nextOffset } =
      await CmsApi.getPublicRelatedArticles(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        Number(parameters.articleId),
        params,
      );

    return {
      ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
      limit: limit!,
      offset: offset!,
      total: total!,
      nextOffset: nextOffset || null,
    };
  },
);

export const loadSimilarArticlesList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.LIST_SIMILAR_ARTICLES)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.LIST_SIMILAR_ARTICLES,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(loadSimilarArticles.action(parameters));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadArticleTabsList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { articles, limit, nextOffset, offset, total } =
              await dispatch(loadArticleTabs.action(parameters));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results: articles,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadArticleTabs = createAsync<
  {
    articleId?: ArticleId;
    appSpace?: string;
    listingType?: string;
  },
  { articles: IArticle[] } & Pager
>('LOAD_ARTICLE_TABS', async (parameters, _, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());
  const listingType = parameters.listingType || 'article-tabs';
  const { articles, limit, offset, total, nextOffset } =
    await CmsApi.getPublicFixedListingArticles4Article(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      Number(parameters.articleId),
      listingType,
    );

  return {
    articles,
    limit: limit!,
    offset: offset!,
    total: total!,
    nextOffset: nextOffset || null,
  };
});

export const loadArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(loadArticles.action(parameters));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadDoctypes = createAsync<
  {
    appSpace?: string;
  },
  { doctypes: Doctype[] } & Pager
>('LOAD_DOCTYPES', async (parameters, _, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());

  const { doctypes } = await CmsApi.getPublicDoctypes(
    config.APP_ID,
    appSpace,
    config.DEFAULT_CONTENT_DIVIDER,
  );

  if (doctypes.length) {
    doctypes.unshift(ALL_DOCTYPES);
  }

  return {
    doctypes,
    total: doctypes.length,
    limit: doctypes.length,
    offset: 0,
    nextOffset: null,
  };
});

export const loadDoctypesList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.LIST_DOCTYPES)(getState());
    return dispatch(
      commit.action({
        listName: config.LIST_DOCTYPES,
        load: async () => {
          try {
            const { doctypes, limit, nextOffset, offset, total } =
              await dispatch(loadDoctypes.action(parameters));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results: doctypes,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
