import ArticleComponent from '@sportnet/ui/Article';
import { getMediaManagerUrl } from '@sportnet/ui/MediaManagerImage';
import * as React from 'react';
import Link from '../Link';
import { ThemeProvider } from 'styled-components';
import { Article as ArticleType } from '../../api/CmsApi';
import formatDate from '../../utilities/formatDate';
import getArticleUrl from '../../utilities/getArticleUrl';
import config from '../../config';

interface Props {
  article: ArticleType;
  className?: string;
}

const getArticleDate = (article: ArticleType) => {
  return article.modified
    ? formatDate(new Date(article.modified), config.DATE_TIME_FORMAT)
    : article.created
    ? formatDate(new Date(article.created), config.DATE_TIME_FORMAT)
    : '';
};

const Article: React.FC<Props> = ({ article, className }) => {
  return (
    <Link
      className={className}
      to={getArticleUrl('', article)}
      draggable={false}
    >
      <ThemeProvider
        theme={{
          separatorColor: '#eee',
        }}
      >
        <ArticleComponent
          title={article.name}
          subtitle={getArticleDate(article)}
          imgSrc={getMediaManagerUrl(article.picture, 360, 270, 'media')}
        />
      </ThemeProvider>
    </Link>
  );
};

export default Article;
