import {
  commit,
  CommitError,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import { getProp } from '@sportnet/utilities';
import CmsApi from '../../api/CmsApi';
import config from '../../config';
import { CustomThunkAction } from '../../configureStore';
import reduceListArticleProps from '../../utilities/reduceListArticleProps';
import { updateEntities } from '../App/actions';
import { normalizeArticles } from '../Article/actions';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

export const loadArticlesList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.LIST_FILTER_ARTICLES)(
      getState(),
    );

    let filter: any[] = [];
    try {
      filter = JSON.parse(parameters.filter);
    } catch (e: any) {
      //
    }

    // append custom pagination
    filter.push({
      type: 'limit',
      value: String(parameters.limit),
    });
    filter.push({
      type: 'offset',
      value: (parameters.page - 1) * parameters.limit,
    });

    const appSpace = CSMAppSpaceSelector(getState());
    return dispatch(
      commit.action({
        listName: config.LIST_FILTER_ARTICLES,
        load: async () => {
          try {
            const response = await CmsApi.expandWidgets(
              config.APP_ID,
              appSpace,
              config.DEFAULT_CONTENT_DIVIDER,
              undefined,
              {
                content: [
                  {
                    type: 'articlelist',
                    articleFilter: filter,
                  },
                ],
              },
            );

            const { articles, limit, nextOffset, offset, total } = getProp(
              response,
              ['content', 0, 'data', 'articleFilter'],
            ) as any;

            const { result, entities } = normalizeArticles(
              articles.map(reduceListArticleProps),
            );

            dispatch(updateEntities(entities));

            return {
              total,
              limit,
              offset,
              nextOffset,
              results: result,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
