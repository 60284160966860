import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { RootState } from '../../configureStore';
import { isLoggedInSelector } from '../../pages/App/selectors';
import { loadArticles } from '../../pages/Article/actions';
import {
  appSettingsSelector,
  headerConfigurationSelector,
} from '../../pages/DomainResolver/selectors';
import getArticleUrl from '../../utilities/getArticleUrl';
import { getCallbackAuthUrl, TOKEN_COOKIE_KEY } from '../Authorization';
import context, { ISearchItem } from './context';
import HeaderFutbalsfz from './wrappers/HeaderFutbalsfz';
import HeaderFutbalsfzCorporate from './wrappers/HeaderFutbalsfzCorporate';
import HeaderSimple from './wrappers/HeaderSimple';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    appSettings: appSettingsSelector(state),
    isLoggedIn: isLoggedInSelector(state),
    headerConfiguration: headerConfigurationSelector(state),
  };
};

const mapDispatchToProps = {
  loadArticles: thunkToAction(loadArticles.action),
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{}, {}> &
  typeof mapDispatchToProps;

const Header: React.FC<Props> = ({
  headerConfiguration,
  isLoggedIn,
  router,
  appSettings,
  loadArticles,
}) => {
  const type = getProp(headerConfiguration, ['type'], '');
  let component: React.ReactChild = <div />;
  switch (type) {
    case 'simple-1':
      component = <HeaderSimple />;
      break;
    case 'futbalsfz':
      component = <HeaderFutbalsfz />;
      break;
    case 'futbalsfz-corporate':
      component = <HeaderFutbalsfzCorporate />;
      break;
    default:
      break;
  }

  const ctxValue = React.useMemo(
    () => ({
      logout: isLoggedIn
        ? () => {
            Cookies.remove(TOKEN_COOKIE_KEY);
            window.location.reload();
          }
        : undefined,
      login: !isLoggedIn
        ? () => {
            window.location.href = getCallbackAuthUrl(
              appSettings.CSMAppSpace || '',
            );
          }
        : undefined,
      getItems: async (q: string) => {
        const response = await loadArticles({
          q,
        });
        return response.original.map((a) => ({
          label: a.name || '',
          icon: a.picture === null ? undefined : a.picture,
          article: a,
        }));
      },
      onSearchSubmitItem: (item: ISearchItem) => {
        const url = getArticleUrl('', item.article);
        if (url.startsWith('http')) {
          window.location.href = url;
        } else {
          router.push({
            pathname: url,
          });
        }
      },
    }),
    [appSettings.CSMAppSpace, isLoggedIn, loadArticles, router],
  );

  return <context.Provider value={ctxValue}>{component}</context.Provider>;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Header);
