import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import __ from '../../../utilities/__';
import Icon from '@sportnet/ui/Icon';

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  margin: ${rem(8)} ${rem(12)};
  .icon {
    margin-right: ${rem(8)};
  }
  font-weight: 600;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: none;
  background: none;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

type IOwnProps = {
  displayName: string;
  wrapperComponent?: React.FC;
  facebook?: string;
  instagram?: string;
  twitter?: string;
};

type IProps = IOwnProps;

const AuthorSocialLinks: React.FC<IProps> = ({
  wrapperComponent = ({ children }) => <div>{children}</div>,
  displayName,
  facebook,
  instagram,
  twitter,
}) => {
  const Wrapper = wrapperComponent;
  return (
    <Wrapper>
      {facebook && (
        <SocialLink
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
          title={`${__('Zobraziť Facebook profil:')} ${displayName}`}
        >
          <Icon name="facebook" size={20} />
          Facebook
        </SocialLink>
      )}
      {twitter && (
        <SocialLink
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
          title={`${__('Zobraziť Twitter profil:')} ${displayName}`}
        >
          <Icon name="twitter" size={20} />
          Twitter
        </SocialLink>
      )}
      {instagram && (
        <SocialLink
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
          title={`${__('Zobraziť Instagram profil:')} ${displayName}`}
        >
          <Icon name="instagram" size={20} />
          Instagram
        </SocialLink>
      )}
    </Wrapper>
  );
};

export default AuthorSocialLinks;
