import { Doctype } from '../api/CmsApi';
import { DeepRequired } from './App';
import { ILayout, IPublicSettings } from './Pages';

interface AppSettings extends IPublicSettings {
  CSMAppSpace: string;
  baseUri: string;
}

export default AppSettings;

export type IHeaderSettings = DeepRequired<ILayout>['settings']['header'];
export type IFooterSettings = DeepRequired<ILayout>['settings']['footer'];

export const ALL_DOCTYPES: Doctype = {
  id: 'AcX4Ts',
  title: 'Všetky',
};
