import FooterDefault from '@sportnet/component-library/Footer';
import { ISection } from '@sportnet/component-library/library/Common';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import { useAsyncData } from 'ssr-service';
import { RootState } from '../../../../configureStore';
import { loadSectionTree } from '../../../../pages/App/actions';
import { sectionTreeSelector } from '../../../../pages/App/selectors';
import { footerConfigurationSelector } from '../../../../pages/DomainResolver/selectors';
import renderLink from '../../../../utilities/renderLink';

const TREELEVEL = 0;

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  const footerConfiguration = footerConfigurationSelector(state);
  const sectionIdOrUniqId = getProp(
    footerConfiguration,
    ['config', 'sectionIdOrUniqId'],
    '',
  ) as string;
  return {
    footerConfiguration,
    sections: sectionTreeSelector({ sectionIdOrUniqId, treelevel: TREELEVEL })(
      state,
    ),
    sectionIdOrUniqId,
  };
};

const mapDispatchToProps = {
  loadSections: loadSectionTree.action,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const FooterSimple: React.FC<Props> = ({
  footerConfiguration,
  sections,
  sectionIdOrUniqId,
  loadSections,
}) => {
  useAsyncData(async () => {
    if (sectionIdOrUniqId) {
      await loadSections({ sectionIdOrUniqId, treelevel: TREELEVEL });
    }
  }, [sectionIdOrUniqId]);

  return (
    <FooterDefault
      configuration={footerConfiguration.config || {}}
      renderLink={renderLink}
      sections={sections as ISection[]}
    />
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FooterSimple,
);
