import {
  commit,
  CommitError,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import config from '../../config';
import { CustomThunkAction } from '../../configureStore';
import { loadArticles } from '../Article/actions';

export const loadSearchResultsList = (): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(config.LIST_SEARCH_RESULTS)(
      getState(),
    );
    return dispatch(
      commit.action({
        listName: config.LIST_SEARCH_RESULTS,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(loadArticles.action(parameters));
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
