import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Doctype } from '../../api/CmsApi';
import styled from '../../theme/styled-components';

const linkClass = 'content-articleTabs-link';
const linkClassActive = 'widget-sectionlist-link-active';
const sectionNameClass = 'widget-sectionlist-sectionName';

const WrapperTab = styled.div`
  margin: 0 auto;
  padding: 0 ${rem(15)};
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  border-bottom: ${rem(1)} solid #7e7e7f;
  width: 100%;
  padding: 0;
  font-size: ${rem(14)};
  > :first-child {
    margin-left: 0px;
  }
  ${mb('s')} {
    padding: 0 ${rem(16)} 0 ${rem(16)};
  }

  .${linkClass} {
    display: block;
    padding: ${rem(10)} ${rem(20)} ${rem(9)} ${rem(20)};
    border: ${rem(1)} solid #7e7e7f;
    margin-bottom: ${rem(-1)};
    background: #f5f5f5;
    font-weight: 100;
    color: ${({ theme }) => theme.color.base};
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    text-decoration: none;
    ${mb('s')} {
      display: inline-block;
      border-top-left-radius: ${rem(5.3)};
      border-top-right-radius: ${rem(5.3)};
      margin-left: ${rem(-1)};
    }
  }

  .${linkClassActive} {
    background: #fff;
    margin-bottom: none;
    color: #000;
    ${mb('s')} {
      border-bottom: ${rem(1)} solid #fff;
    }
  }
`;

type IOwnProps = {
  selectedDoctypes: string[];
  doctypes: Doctype[];
  onChange: (doctype: string) => void;
};

type IProps = IOwnProps;

const DoctypesTabs: React.FC<IProps> = ({
  selectedDoctypes,
  doctypes,
  onChange,
}) => {
  if (doctypes.length <= 0) {
    return null;
  }

  return (
    <WrapperTab className="content-articletabs-tabs">
      {doctypes.map((doctype) => {
        const isActive = selectedDoctypes.includes(doctype.id);
        return (
          <div
            key={`doctype-tab-${doctype.id}`}
            className={`${linkClass} ${isActive && linkClassActive}`}
            onClick={() => {
              onChange(doctype.id);
            }}
          >
            <span className={`${sectionNameClass}`}>{doctype.title}</span>
          </div>
        );
      })}
    </WrapperTab>
  );
};

export default React.memo(DoctypesTabs);
