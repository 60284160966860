import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { getListResults } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import { Title } from '../../components/Layout';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import Loading from '../../components/Loading';
import config from '../../config';
import { RootState } from '../../configureStore';
import AuthorPreview from './AuthorPreview';
import { IPPOUser } from '../../library/Sportnet';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { loadRedakciaList } from './actions';
import { listRedakciaSelector } from './selectors';
import EntryAnimation from '../../components/EntryAnimation';
import Breadcrumbs from '../../components/Breadcrumbs';

const mapStateToProps = (state: RootState) => {
  return {
    users: listRedakciaSelector(state),
    isFetching: !getListResults(config.LIST_REDAKCIA)(state),
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadRedakciaList,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, {}>;

const TitleWrapper = styled.div`
  margin: ${rem(10)} 0 1em 0;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;
  ${mb('m')} {
    display: block;
  }
`;

const RedakciaAuthorsWrapper = styled.div`
  margin-top: ${rem(48)};
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const StyledAuthor = styled.div`
  width: 100%;
  ${mb('m')} {
    width: 50%;
    &:nth-child(odd) {
      padding-right: ${rem(48)};
    }
  }
`;

const Redakcia: React.FC<Props> = ({
  initializeOrSetListParams,
  loadRedakciaList,
  users,
  isFetching,
  appSettings,
}) => {
  // tu nacitame list redaktorov
  const appSpaceId = appSettings.CSMAppSpace!;
  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_REDAKCIA,
      params: {
        appSpaceId,
        limit: 1000,
        offset: 0,
        bioActive: true,
      },
    });
    await loadRedakciaList(config.LIST_REDAKCIA);
  }, [initializeOrSetListParams, loadRedakciaList, appSpaceId]);

  const title = __('Redakcia');

  return (
    <EntryAnimation key={'/redakcia'}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <MaxWidthBox width={config.WIDER_CONTENT_MAX_WIDTH}>
        <StyledBreadcrumbs
          crumbs={[
            {
              label: 'Redakcia',
              url: '/redakcia',
            },
          ]}
        />
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        {isFetching ? (
          <Loading />
        ) : (
          <RedakciaAuthorsWrapper>
            {users.map((user: IPPOUser) => {
              return (
                <StyledAuthor key={user._id}>
                  <AuthorPreview
                    appSpaceId={appSpaceId}
                    userId={user._id}
                    overwrite={{
                      facebook: '',
                      twitter: '',
                      instagram: '',
                      perex: '',
                    }}
                  />
                </StyledAuthor>
              );
            })}
          </RedakciaAuthorsWrapper>
        )}
      </MaxWidthBox>
    </EntryAnimation>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Redakcia);
