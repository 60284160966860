import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ArticleId, SectionId } from '../../library/App';
import * as actions from './actions';

export type SectionArticlesByIdState = Readonly<{
  [key: string]: Readonly<{
    isFetching: boolean;
    articles: ReadonlyArray<ArticleId>;
    nextOffset: Readonly<number | null>;
  }>;
}>;

const SECTION_ARTICLES_INITIAL_STATE: SectionArticlesByIdState = {};

export const sectionArticlesByIdReducer =
  reducerWithInitialState<SectionArticlesByIdState>(
    SECTION_ARTICLES_INITIAL_STATE,
  )
    .case(
      actions.loadSectionArticles.async.started,
      (state, articleId: number): SectionArticlesByIdState => ({
        ...state,
        [articleId]: {
          ...state[articleId],
          isFetching: true,
        },
      }),
    )
    .case(
      actions.loadSectionArticles.async.done,
      (state, { params: articleId, result }): SectionArticlesByIdState => ({
        ...state,
        [articleId]: {
          isFetching: false,
          articles: result.results,
          nextOffset: result.nextOffset,
        },
      }),
    )
    .case(
      actions.loadSectionArticles.async.failed,
      (state, { params: articleId }): SectionArticlesByIdState => ({
        ...state,
        [articleId]: {
          isFetching: false,
        },
      }),
    );

export const currentSectionIdReducer =
  reducerWithInitialState<SectionId | null>(null).case(
    actions.setCurrentSectionId,
    (state, sectionId) => sectionId,
  );
