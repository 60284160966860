import DefaultPaginator from '@sportnet/ui/Paginator';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import * as React from 'react';
import { ThemeProvider, withTheme } from 'styled-components';
import config from '../../config';
import { ITheme } from '../../theme/theme';
import { rem } from 'polished';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(15)};
`;

interface OwnProps {
  limit: number;
  page: number;
  total: number;
  onChangePage: (page: number) => void;
  loading?: boolean;
  theme: ITheme;
}

type Props = OwnProps;

const Paginator: React.FC<Props> = ({
  limit,
  page,
  total,
  onChangePage,
  loading,
  theme,
}) => {
  function handleChangeOffset(offset = 0) {
    let newPage = offset / limit + 1;
    if (newPage < 1) {
      newPage = 1;
    }
    onChangePage(newPage);
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>
        <ThemeProvider
          theme={{
            paginator: theme.paginator,
          }}
        >
          <DefaultPaginator
            limit={limit}
            offset={(page - 1) * limit || 0}
            total={total}
            onChangeOffset={handleChangeOffset}
            loading={loading}
          />
        </ThemeProvider>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default withTheme(Paginator);
