import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { RootState } from '../../configureStore';
import { appSettingsSelector } from '../../pages/DomainResolver/selectors';
import { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

type OwnProps = {
  theme: ITheme;
};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps & RouteComponentProps<void, void>;

const Analytics: React.FC<Props> = ({ appSettings }) => {
  const tagManagerId = appSettings.googleTagmanager?.id;
  const analyticsId = appSettings.googleAnalytics?.id;

  if (tagManagerId) {
    TagManager.initialize({ gtmId: tagManagerId });
  }

  if (analyticsId) {
    ReactGA.initialize(analyticsId);
  }

  return null;
};

export default compose(withTheme, connect(mapStateToProps))(Analytics);
