import { rem } from 'polished';
import * as React from 'react';
import { keyframes } from 'styled-components';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.app.textColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const scale = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const fade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const Text = styled.span`
  font-family: sans-serif;
  font-size: ${rem(18)};
  font-weight: bold;
  animation: ${scale} 2s infinite, ${fade} 2s infinite;
`;

const Loader: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Text>{__('Načítavam obsah')}</Text>
    </Wrapper>
  );
};

export default Loader;
