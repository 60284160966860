import MediaManagerImage from '@sportnet/ui/MediaManagerImage';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import { MediaManagerPhoto } from '../../../library/Sportnet';
import styled from '../../../theme/styled-components';
import AuthorSocialLinks from './socialLinks';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkedImage = styled(Link)`
  flex-shrink: 0;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  > img {
    border-radius: 50%;
    height: ${rem(48)};
    ${mb('s')} {
      height: ${rem(80)};
    }
  }
  margin-right: ${rem(16)};
  ${mb('s')} {
    margin-right: ${rem(24)};
  }
  flex-shrink: 0;
`;

const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  word-break: break-word;
`;

const Name = styled.span`
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`;
const Perex = styled.span`
  display: none;
  ${mb('s')} {
    display: inline;
  }
`;
const Position = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-size: 90%;
  margin-top: -${rem(8)};
`;

const SocialLinksWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: flex;
  }
  flex-wrap: wrap;
  margin: ${rem(8)} ${rem(-12)} ${rem(-8)} ${rem(-12)};
`;

const NoPhoto = styled.div`
  position: relative;
  width: ${rem(48)};
  height: ${rem(48)};
  ${mb('s')} {
    width: 80px;
    height: 80px;
    margin-right: ${rem(24)};
  }

  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.app.textColor};
  margin-right: ${rem(12)};
`;

const PhotoPlaceholder = styled.div`
  position: relative;
  margin-right: ${rem(12)};
  ${mb('s')} {
    width: ${rem(80)};
    height: ${rem(80)};
    margin-right: ${rem(24)};
  }
  width: ${rem(48)};
  height: ${rem(48)};
`;

type IOwnProps = {
  displayName: string;
  facebook: string;
  instagram: string;
  perex: string;
  twitter: string;
  photo: MediaManagerPhoto | null;
  className?: string;
  authorUrl?: string;
  position?: string;
};

type IProps = IOwnProps;

const Author: React.FC<IProps> = ({
  displayName,
  facebook,
  instagram,
  perex,
  twitter,
  photo,
  className,
  authorUrl,
  position,
}) => {
  return (
    <Wrapper className={className}>
      {photo ? (
        authorUrl ? (
          <PhotoPlaceholder>
            <LinkedImage to={authorUrl}>
              <StyledMediaManagerImage
                picture={photo}
                naturalHeight={80 * 2}
                naturalWidth={80 * 2}
                script="media"
                webp={true}
                loading="lazy"
              />
            </LinkedImage>
          </PhotoPlaceholder>
        ) : (
          <StyledMediaManagerImage
            picture={photo}
            naturalHeight={80 * 2}
            naturalWidth={80 * 2}
            script="media"
            webp={true}
            loading="lazy"
          />
        )
      ) : (
        <NoPhoto />
      )}

      <Description>
        {position && <Position>{position}</Position>}
        {authorUrl ? (
          <Link to={authorUrl}>
            <Name>{displayName}</Name>
          </Link>
        ) : (
          <Name>{displayName}</Name>
        )}

        <Perex>{perex}</Perex>

        <AuthorSocialLinks
          wrapperComponent={SocialLinksWrapper}
          {...{ facebook, twitter, instagram, displayName }}
        />
      </Description>
    </Wrapper>
  );
};

export default Author;
