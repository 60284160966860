import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from '@sportnet/utilities/at';
import { Doctype } from '../../api/CmsApi';
import config from '../../config';
import { RootState } from '../../configureStore';
import { IArticle } from '../../library/App';
import {
  detailTypeSelector,
  entitiesSelector,
} from '../../pages/App/selectors';
import { IDetailInitialState } from '../App/reducer';

// Current article id
export const currentArticleIdSelector = (state: RootState) =>
  state.currentArticleId;

const currentArticleDomain = createSelector(
  currentArticleIdSelector,
  detailTypeSelector('articleById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentArticleIsFetchingSelector = createSelector(
  currentArticleDomain,
  (domain) => !!domain.isFetching,
);

export const currentArticleErrorSelector = createSelector(
  currentArticleDomain,
  (domain) => domain.error,
);

export const currentArticleSelector = createSelector(
  entitiesSelector,
  currentArticleIdSelector,
  (entities, id) =>
    id !== null && id in entities.articles ? entities.articles[id] : null,
);

export const similarArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SIMILAR_ARTICLES),
  (entities: any, ids) => (at as any)(entities.articles, ids || []),
);

export const articleTabsSelector = (state: RootState) =>
  (getListResults(config.LIST_ARTICLE_TABS_ARTICLES)(state) ||
    []) as IArticle[];

export const doctypesSelector = (state: RootState) =>
  (getListResults(config.LIST_DOCTYPES)(state) || []) as Doctype[];
