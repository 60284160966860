import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { Article } from '../../api/CmsApi';
import Loading from '../../components/Loading';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { errorDetailSelector, isFetchingDetail } from '../App/selectors';
import NotFound from '../NotFound';
import { loadPreview } from './actions';
import ArticlePreview from './ArticlePreview';
import { previewDetailSelector } from './selectors';

type RouterProps = RouteComponentProps<
  {
    previewId: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps & ReduxConnectProps,
) => {
  const { previewId } = props.params;
  return {
    isFetching: isFetchingDetail('previewById', { id: previewId })(state),
    detail: previewDetailSelector(previewId)(state),
    error: errorDetailSelector('previewById', { id: previewId })(state),
  };
};

type Props = RouterProps &
  ReduxConnectProps &
  ReturnType<typeof mapStateToProps>;

class Preview extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    return props.dispatch(loadPreview.action({ id: props.params.previewId }));
  }

  async componentDidMount() {
    try {
      await Preview.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.params.previewId !== this.props.params.previewId) {
      try {
        await Preview.getInitialProps(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  render() {
    const { isFetching, detail } = this.props;

    if (isFetching && detail === null) {
      return <Loading />;
    }

    if (detail) {
      if (detail.type === 'article') {
        return <ArticlePreview article={detail.data as Article} />;
      }
    }

    return <NotFound />;
  }
}

export default compose(connect(mapStateToProps))(Preview);
