import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import getTime from 'date-fns/getTime';
import { rem } from 'polished';
import * as React from 'react';
import config from '../../config';
import styled from '../../theme/styled-components';
import formatDate from '../../utilities/formatDate';
import __ from '../../utilities/__';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.contentMetaInformation.textColor};
  margin: ${rem(20)} 0 ${rem(15)} 0;
  line-height: 1.3;
`;

const Day = styled.span`
  text-transform: capitalize;
`;

const Pipe = styled.span`
  margin: 0 ${rem(3)};
`;

interface Props {
  createdDate: string | null;
  modifiedDate?: string | null;
}

const ContentMetaInformation: React.FC<Props> = ({
  createdDate,
  modifiedDate,
  children,
}) => {
  let createDateElement = null;
  let modifiedDateElement = null;
  let createdDateInUnix = 0;
  let modifiedDateInUnix = 0;

  if (createdDate) {
    createDateElement = formatDate(
      new Date(createdDate),
      config.CREATED_DATE_FORMAT,
    );
    createdDateInUnix = getTime(new Date(createdDate));
  }

  if (modifiedDate) {
    modifiedDateElement = `${__('Posledná aktualizácia')} ${formatDate(
      new Date(modifiedDate),
      config.DATE_TIME_FORMAT,
    )}`;
    modifiedDateInUnix = getTime(new Date(modifiedDate));
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>
        {modifiedDateInUnix - createdDateInUnix < 2 ? (
          createDateElement && <Day>{createDateElement}</Day>
        ) : (
          <>
            {createDateElement && <Day>{createDateElement}</Day>}
            {modifiedDateElement && createDateElement ? <Pipe>|</Pipe> : null}
            {modifiedDateElement}
          </>
        )}

        {children && (
          <>
            <br />
            {children}
          </>
        )}
      </Wrapper>
    </MaxWidthBox>
  );
};

export default ContentMetaInformation;
