import { css } from '@sportnet/ui/Themes/styled-components';
import { mb } from '@sportnet/ui/Themes/utilities';
import { em } from 'polished';
import { getAppFontConfig } from '../../containers/Fonts/utils';
import styled from '../../theme/styled-components';

export const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  ${({ theme }) => getAppFontConfig(theme).css}
  color: ${({ theme }) => theme.app.textColor};
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1<{ isCentered?: boolean }>`
  display: inline;
  vertical-align: middle;
  line-height: 1.25;
  font-size: ${em(36)};
  ${mb('m')} {
    font-size: ${em(44)};
  }
  color: ${({ theme }) => theme.title.textColor};
  margin: 0;
  padding: 0;
  font-weight: normal;
  word-break: break-word;
  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `}
`;

export const SecondTitle = styled.h2<{ isCentered?: boolean }>`
  display: inline;
  vertical-align: middle;
  line-height: 1.25;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.title.textColor};
  margin: 0;
  padding: 0;
  font-weight: normal;
  word-break: break-word;
  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `}
`;
