import { filterToUrl } from '@sportnet/content/utilities/utilities';
import * as React from 'react';
import { getProp } from '@sportnet/utilities';
import { SectionPublic } from '../api/CmsApi';
import Link from '../components/Link';
import getArticleUrl from './getArticleUrl';
import getSectionUrl from './getSectionUrl';

export default function (link: any) {
  if (link.type === 'article_detail') {
    return (
      <Link to={getArticleUrl('', link.payload)} {...link.linkProps}>
        {link.linkProps.children}
      </Link>
    );
  }

  if (link.type === 'article_filter') {
    const header = link.payload.header;
    const urlSegments = ['filter?'];
    if (header) {
      urlSegments.push(`header=${header}`);
    }
    const filter = getProp(link, ['payload', 'filter'], []);
    if (filter.length > 0) {
      urlSegments.push(
        filterToUrl(filter.filter((a: any) => a.type !== 'limit')),
      );
    }
    return (
      <Link to={urlSegments.join('&')} {...link.linkProps}>
        {link.linkProps.children}
      </Link>
    );
  }

  if (link.section) {
    return (
      <Link
        {...link.linkProps}
        to={getSectionUrl('', link.section as SectionPublic)}
      >
        {link.linkProps.children}
      </Link>
    );
  }

  return null;
}
