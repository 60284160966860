import React from 'react';
import { Helmet } from 'react-helmet';
import { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import { getAppFontConfig } from './utils';

type OwnProps = {
  theme: ITheme;
};

type Props = OwnProps;

const Fonts: React.FC<Props> = ({ theme }) => {
  const fontLink = getAppFontConfig(theme).link;

  if (!fontLink) {
    return null;
  }

  return (
    <Helmet>
      <link href={fontLink} rel="stylesheet" />
    </Helmet>
  );
};

export default withTheme(Fonts);
