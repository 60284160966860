import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import {
  CustomThunkDispatch,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import {
  IAuthorizationResponse,
  INormalizedSectionNode,
  INormalizedSectionTree,
  NormalizedEntities,
  Pager,
  SectionId,
  SectionNode,
  SectionTreeType,
  Writeable,
} from '../../library/App';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import updateEntitiesUtility from '../../utilities/updateEntities';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';
import { entitiesSelector } from './selectors';
import reducePPOUserProps from '../../utilities/reducePPOUserProps';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAuthUser = create<IAuthorizationResponse>('SET_AUTH_USER');

export const loadPath = createAsync<
  {
    id: SectionId;
  },
  NormalizedEntities<'sections'> & { data: { path: string[] | number[] } }
>('LOAD_PATH', async ({ id }, dispatch, getState, { CmsApi }) => {
  const appSpace: string = CSMAppSpaceSelector(getState());

  const response = await CmsApi.getPathForSectionId(
    config.APP_ID,
    appSpace,
    config.DEFAULT_CONTENT_DIVIDER,
    id,
  );
  const normalized = normalizeEntities(
    'sections',
    response.path!.map(reduceSectionProps), // @TODO csm/api path je optional
  );
  return {
    ...normalized,
    data: {
      path: normalized.results,
    },
  };
});

export const loadSectionTree = createAsync<
  {
    sectionIdOrUniqId: string;
    treelevel: number;
  },
  {
    entities: {
      sections: {
        [key: string]: SectionNode;
      };
    };
    data: {
      tree: SectionTreeType;
    };
  }
>(
  'LOAD_SECTION_TREE',
  async ({ sectionIdOrUniqId, treelevel }, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());
    const response = await CmsApi.getPublicSubsectionsByIdOrUniqId(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      sectionIdOrUniqId,
      {
        treelevel,
      },
    );

    const sectionsEntities: {
      [key: string]: SectionNode;
    } = {};

    const normalizeTree = (
      sections: SectionTreeType,
    ): INormalizedSectionTree => {
      return sections.map((section) => {
        sectionsEntities[section._id!] = reduceSectionProps(section);

        const sectionNode: INormalizedSectionNode = {
          _id: section._id!,
        };

        if (section.sections && section.sections.length > 0) {
          const normalizedSections = normalizeTree(section.sections);
          sectionNode.sections = normalizedSections;
        }

        return sectionNode;
      });
    };

    const tree = normalizeTree(response.sections!); // @TODO csm/api optional type

    return {
      entities: {
        sections: sectionsEntities,
      },
      data: {
        tree,
      },
    };
  },
);

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const updateEntities = (
  entitiesToUpdate: Partial<RootState['entities']>,
) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch: CustomThunkDispatch, getState: () => RootState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities: Partial<RootState['entities']>) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const loadPPOUsers = createAsync<
  {
    appSpaceId: string;
    params: {
      limit?: number;
      offset?: number;
      ids?: string[];
      bioActive?: boolean;
    };
  },
  NormalizedEntities<'ppoUsers'> & Pager
>(
  'LOAD_PPO_USERS',
  async ({ appSpaceId, params }, dispatch, getState, { CoreApi }) => {
    const response = await CoreApi.organizationPPOUsers(appSpaceId, params);
    const ppoUsers = (response.users || []).map((user) =>
      reducePPOUserProps(user),
    );

    const normalizedEntities = normalizeEntities(
      'ppoUsers',
      ppoUsers,
      (u) => `${appSpaceId}-${u._id}`,
    );

    dispatch(updateEntities(normalizedEntities.entities));

    return {
      ...normalizedEntities,
      total: response.total!,
      limit: response.limit!,
      offset: response.offset!,
      nextOffset: response.next_offset,
    };
  },
);
