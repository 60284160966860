/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description: string;

  payload?: {};

  userinfo?: string;
};
export type elementary_email = string;
export type Settings_Base_googleAnalytics = {
  id: string;
};
export type Settings_Base_googleTagmanager = {
  id: string;
};
export type Settings_Base_theme = {
  color: {
    primary: string;
  };

  app?: {};
};
export type Settings_Base_homepageSectionIdOrUniqId = string;
export type Settings_Base_pageTitle = string;
export type Settings_Base_logo = {};
export type Settings_Base_logo_small = {};
export type Settings_Base_favicon = {};
export type Settings_Base_header = {
  type: string;

  config?: {};
};
export type Settings_Base_footer = {
  type: string;

  config?: {};
};
export type Settings_Base_contactForm = {
  from: string;

  fromName: string;

  recipients: Array<string>;

  subject: Array<string>;

  description?: string;
};
export type Settings_Base_domain = string;
export type Settings_Base_social = {};
export type Settings_Base_Section_Layout_Map = Array<{
  sectionId: number;

  layoutId: string;
}>;
export type Settings_Base_metatag = {
  content: string;

  name?: string;

  property?: string;
};
export type Settings_Article_Layouts = Array<{
  _id: string;

  name: string;
}>;
export type Settings_Edit = {
  googleAnalytics?: {
    id: string;
  };

  googleTagmanager?: {
    id: string;
  };

  homepageSectionIdOrUniqId?: string;

  contactForm?: {
    from: string;

    fromName: string;

    recipients: Array<string>;

    subject: Array<string>;

    description?: string;
  };

  domain?: string;

  social?: {};

  sectionLayoutMap?: Array<{
    sectionId: number;

    layoutId: string;
  }>;
};
export type Admin_Settings = {
  googleAnalytics?: {
    id: string;
  };

  googleTagmanager?: {
    id: string;
  };

  homepageSectionIdOrUniqId?: string;

  contactForm?: {
    from: string;

    fromName: string;

    recipients: Array<string>;

    subject: Array<string>;

    description?: string;
  };

  domain?: string;

  social?: {};

  sectionLayoutMap?: Array<{
    sectionId: number;

    layoutId: string;
  }>;

  articleLayouts?: Array<{
    _id: string;

    name: string;
  }>;
};
export type Settings = {
  googleAnalytics?: {
    id: string;
  };

  googleTagmanager?: {
    id: string;
  };

  homepageSectionIdOrUniqId?: string;

  contactForm?: {
    from: string;

    fromName: string;

    recipients: Array<string>;

    subject: Array<string>;

    description?: string;
  };

  domain?: string;

  social?: {};

  sectionLayoutMap?: Array<{
    sectionId: number;

    layoutId: string;
  }>;

  articleLayouts?: Array<{
    _id: string;

    name: string;
  }>;

  layouts?: Array<{
    name: string;

    default: boolean;

    settings?: {
      header?: {
        type: string;

        config?: {};
      };

      footer?: {
        type: string;

        config?: {};
      };

      theme?: {
        color: {
          primary: string;
        };

        app?: {};
      };

      pageTitle?: string;

      logo?: {};

      logoSmall?: {};

      favicon?: {};

      metatags?: Array<{
        content: string;

        name?: string;

        property?: string;
      }>;

      customLogo?: {};

      customFavicon?: {};
    };

    _id?: string;
  }>;
};
export type Layout_Settings = {
  header?: {
    type: string;

    config?: {};
  };

  footer?: {
    type: string;

    config?: {};
  };

  theme?: {
    color: {
      primary: string;
    };

    app?: {};
  };

  pageTitle?: string;

  logo?: {};

  logoSmall?: {};

  favicon?: {};

  metatags?: Array<{
    content: string;

    name?: string;

    property?: string;
  }>;

  customLogo?: {};

  customFavicon?: {};
};
export type Layout_Create_Or_Update = {
  name: string;

  default: boolean;

  settings?: {
    header?: {
      type: string;

      config?: {};
    };

    footer?: {
      type: string;

      config?: {};
    };

    theme?: {
      color: {
        primary: string;
      };

      app?: {};
    };

    pageTitle?: string;

    logo?: {};

    logoSmall?: {};

    favicon?: {};

    metatags?: Array<{
      content: string;

      name?: string;

      property?: string;
    }>;

    customLogo?: {};

    customFavicon?: {};
  };
};
export type Layout = {
  name: string;

  default: boolean;

  settings?: {
    header?: {
      type: string;

      config?: {};
    };

    footer?: {
      type: string;

      config?: {};
    };

    theme?: {
      color: {
        primary: string;
      };

      app?: {};
    };

    pageTitle?: string;

    logo?: {};

    logoSmall?: {};

    favicon?: {};

    metatags?: Array<{
      content: string;

      name?: string;

      property?: string;
    }>;

    customLogo?: {};

    customFavicon?: {};
  };

  _id?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class PagesApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class PagesApi {
  protected baseUrl: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Fetch Settings for Given Contentspace
   * @method
   * @name PagesApi#publicGetSettings
   */
  publicGetSettings(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    googleAnalytics?: {
      id: string;
    };

    googleTagmanager?: {
      id: string;
    };

    homepageSectionIdOrUniqId?: string;

    contactForm?: {
      from: string;

      fromName: string;

      recipients: Array<string>;

      subject: Array<string>;

      description?: string;
    };

    domain?: string;

    social?: {};

    sectionLayoutMap?: Array<{
      sectionId: number;

      layoutId: string;
    }>;

    articleLayouts?: Array<{
      _id: string;

      name: string;
    }>;

    layouts?: Array<{
      name: string;

      default: boolean;

      settings?: {
        header?: {
          type: string;

          config?: {};
        };

        footer?: {
          type: string;

          config?: {};
        };

        theme?: {
          color: {
            primary: string;
          };

          app?: {};
        };

        pageTitle?: string;

        logo?: {};

        logoSmall?: {};

        favicon?: {};

        metatags?: Array<{
          content: string;

          name?: string;

          property?: string;
        }>;

        customLogo?: {};

        customFavicon?: {};
      };

      _id?: string;
    }>;
  }> {
    let path = '/public/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Fetch Settings for Given Contentspace
   * @method
   * @name PagesApi#publicGetSettingsSegment
   */
  publicGetSettingsSegment(
    appSpace: string,
    segment: string,
    parameters: {} = {},
  ): Promise<{
    googleAnalytics?: {
      id: string;
    };

    googleTagmanager?: {
      id: string;
    };

    homepageSectionIdOrUniqId?: string;

    contactForm?: {
      from: string;

      fromName: string;

      recipients: Array<string>;

      subject: Array<string>;

      description?: string;
    };

    domain?: string;

    social?: {};

    sectionLayoutMap?: Array<{
      sectionId: number;

      layoutId: string;
    }>;

    articleLayouts?: Array<{
      _id: string;

      name: string;
    }>;

    layouts?: Array<{
      name: string;

      default: boolean;

      settings?: {
        header?: {
          type: string;

          config?: {};
        };

        footer?: {
          type: string;

          config?: {};
        };

        theme?: {
          color: {
            primary: string;
          };

          app?: {};
        };

        pageTitle?: string;

        logo?: {};

        logoSmall?: {};

        favicon?: {};

        metatags?: Array<{
          content: string;

          name?: string;

          property?: string;
        }>;

        customLogo?: {};

        customFavicon?: {};
      };

      _id?: string;
    }>;
  }> {
    let path = '/public/{appSpace}/settings/{segment}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{segment}', segment.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Sends a mail from any contact form using data from app space settings
   * @method
   * @name PagesApi#webContactFormRequest
   */
  webContactFormRequest(
    appSpace: string,
    parameters: {} = {},
    body: {
      name: string;

      email: string;

      subject: string;

      phone?: string;

      message: string;
    },
  ): Promise<{}> {
    let path = '/public/{appSpace}/request/web-contact-form';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
}

export default new PagesApi();
export { PagesApi };
