import HeaderFutbalsfzCorporateDefault from '@sportnet/component-library/HeaderFutbalsfzCorporate';
import { ISection } from '@sportnet/component-library/library/Common';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import { useAsyncData } from 'ssr-service';
import { RootState } from '../../../../configureStore';
import { loadSectionTree } from '../../../../pages/App/actions';
import { sectionTreeSelector } from '../../../../pages/App/selectors';
import { headerConfigurationSelector } from '../../../../pages/DomainResolver/selectors';
import renderLink from '../../../../utilities/renderLink';
import context from '../../context';

const TREELEVEL = 2;

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  const headerConfiguration = headerConfigurationSelector(state);
  const sectionIdOrUniqId = getProp(
    headerConfiguration,
    ['config', 'sectionIdOrUniqId'],
    '',
  ) as string;
  const corporateSectionIdOrUniqId = getProp(
    headerConfiguration,
    ['config', 'corporateSectionIdOrUniqId'],
    '',
  ) as string;
  return {
    headerConfiguration,
    sections: sectionTreeSelector({ sectionIdOrUniqId, treelevel: TREELEVEL })(
      state,
    ),
    corporateSections: sectionTreeSelector({
      sectionIdOrUniqId: corporateSectionIdOrUniqId,
      treelevel: TREELEVEL,
    })(state),
    sectionIdOrUniqId,
    corporateSectionIdOrUniqId,
  };
};

const mapDispatchToProps = {
  loadSections: loadSectionTree.action,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{}, {}> &
  typeof mapDispatchToProps;

const HeaderFutbalsfzCorporate: React.FC<Props> = ({
  headerConfiguration,
  sections,
  corporateSections,
  router,
  loadSections,
  sectionIdOrUniqId,
  corporateSectionIdOrUniqId,
}) => {
  const ctx = React.useContext(context);
  const [searchQuery, setSearchQuery] = React.useState('');

  useAsyncData(async () => {
    await Promise.all([
      sectionIdOrUniqId &&
        loadSections({ sectionIdOrUniqId, treelevel: TREELEVEL }),
      corporateSectionIdOrUniqId &&
        loadSections({
          sectionIdOrUniqId: corporateSectionIdOrUniqId,
          treelevel: TREELEVEL,
        }),
    ]);
  }, [sectionIdOrUniqId, corporateSectionIdOrUniqId]);

  return (
    <HeaderFutbalsfzCorporateDefault
      configuration={headerConfiguration.config}
      renderLink={renderLink}
      sections={sections as ISection[]}
      corporateSections={corporateSections as ISection[]}
      onChangeSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      onSearchSubmit={() => {
        router.push({
          pathname: '/search',
          search: `?q=${searchQuery}`,
        });
      }}
      onClickLogin={ctx.login}
      onClickLogout={ctx.logout}
      getItems={ctx.getItems}
      onSearchSubmitItem={ctx.onSearchSubmitItem}
    />
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderFutbalsfzCorporate);
