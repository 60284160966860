import * as React from 'react';
import { IPicture } from '@sportnet/ui/MediaManagerImage';
import { Article } from '../../api/CmsApi';

export interface ISearchItem {
  label: string;
  icon?: IPicture;
  article: Article;
}

export default React.createContext<{
  logout?: () => void;
  login?: () => void;
  getItems?: (q: string) => Promise<ISearchItem[]>;
  onSearchSubmitItem?: (item: ISearchItem) => void;
}>({});
