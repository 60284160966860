import { Article } from '../api/CmsApi';

export default function (baseUri: string, article: Article) {
  if (article.redirect) {
    return article.redirect;
  }
  if (article.url) {
    return `${baseUri}/${article.url}`;
  }
  return '';
}
