import Icon from '@sportnet/ui/Icon';
import { mb } from '@sportnet/ui/Themes/utilities';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { em, rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { compose } from 'redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Title } from '../../components/Layout';
import config from '../../config';
import { RootState } from '../../configureStore';
import {
  currentSectionIdSelector,
  pathSelector,
} from '../../pages/Section/selectors';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import getSectionUrl from '../../utilities/getSectionUrl';

const Wrapper = styled.div`
  display: flex;
  margin: ${rem(10)} 0 1em 0;
  align-items: center;
`;

const StyledBackLink = styled(Link)`
  flex-shrink: 0;
  display: block;
  ${mb('m')} {
    display: none;
  }
`;

const TitleWrapper = styled.div``;

const TitleImg = styled.img`
  vertical-align: middle;
  display: inline-block;
  margin-right: ${rem(8)};
  height: ${em(38)};
  width: ${em(38)};
  ${mb('m')} {
    height: ${em(46)};
    width: ${em(46)};
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;
  ${mb('m')} {
    display: block;
  }
`;

const mapStateToProps = (state: RootState) => ({
  currentPath: pathSelector(currentSectionIdSelector(state))(state),
});

interface IOwnProps {
  picture?: string | null;
  title: string;
}

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> & {
    theme: ITheme;
  };

const C: React.FC<IProps> = ({ title, picture, currentPath, theme }) => {
  const lastItemInPath = currentPath[currentPath.length - 2];
  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <StyledBreadcrumbs
        crumbs={currentPath.map((section) => ({
          label: section.name || '',
          url: getSectionUrl('', section),
        }))}
      />
      <Wrapper>
        {lastItemInPath && (
          <StyledBackLink to={getSectionUrl('', lastItemInPath)}>
            <Icon size={48} name="arrow-left" color={theme.title.textColor} />
          </StyledBackLink>
        )}
        <TitleWrapper>
          {picture && <TitleImg src={picture} />}
          <Title>{title}</Title>
        </TitleWrapper>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default compose(
  withTheme,
  connect(mapStateToProps),
)(C) as any as React.FC<IOwnProps>;
