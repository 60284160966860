import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';
import { RootState } from '../../configureStore';
import { SectionTreeType } from '../../library/App';
import serializeParameters from '../../utilities/serializeParameters';
import { IDetailInitialState } from './reducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

// Detail

export const detailTypeSelector = <T extends keyof RootState['detail']>(
  type: T,
) =>
  createSelector(
    (state: RootState) => state.detail,
    () => type,
    (domain, detailType) => domain[detailType],
  );

const detailDomainSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(
    detailTypeSelector(type),
    () => params,
    (detailType, parameters) => {
      const key = serializeParameters(parameters, Object.keys(params));
      const r = getProp(detailType, [key], {}) as Partial<
        IDetailInitialState<any>['']
      >;
      return r;
    },
  );

export const isFetchingDetail = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(
    detailDomainSelector(type, params),
    (domain) => !!domain.isFetching,
  );

export const didFailDetail = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(
    detailDomainSelector(type, params),
    (domain) => !!domain.error,
  );

export const errorDetailSelector = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(detailDomainSelector(type, params), (domain) => domain.error);

export const detailDataSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(detailDomainSelector(type, params), (domain) => domain.data);

// Section Tree
export const sectionTreeSelector = (params: {
  sectionIdOrUniqId: string;
  treelevel: number;
}) =>
  createSelector(
    entitiesSelector,
    detailDataSelector('sectionTreeBySectionIdOrUniqId', params),
    (entities, treeData): SectionTreeType => {
      const tree = getProp(treeData, ['tree'], []);

      const traverseTree = (sections: SectionTreeType): SectionTreeType => {
        return sections.map((section) => {
          if (section.sections && section.sections.length > 0) {
            return {
              ...entities.sections[section._id!],
              sections: traverseTree(section.sections),
            };
          }
          return entities.sections[section._id!];
        });
      };

      return traverseTree(tree);
    },
  );

const authorizationDomain = (state: RootState) =>
  state.application.authorization;

export const accessTokenSelector = createSelector(
  authorizationDomain,
  (domain) => domain && domain.accessToken,
);

export const isLoggedInSelector = createSelector(
  authorizationDomain,
  (domain) => domain && domain.user,
);

export const ppoUserEntitiesSelector = (state: RootState) =>
  state.entities.ppoUsers;

export const ppoUserSelector = createSelector(
  ppoUserEntitiesSelector,
  (_: RootState, { appSpaceId }: { appSpaceId: string }) => appSpaceId,
  (_: RootState, { userId }: { userId: string }) => userId,
  (byId, appSpaceId, userId) => {
    const id = `${appSpaceId}-${userId}`;
    return byId[id] || null;
  },
);
