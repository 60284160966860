import MediaManagerImage from '@sportnet/ui/MediaManagerImage';
import Paginator from '@sportnet/ui/Paginator';
import { mb } from '@sportnet/ui/Themes/utilities';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import useQuery, { NumberParam } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { formatUserName } from '@sportnet/utilities';
import { useAsyncData } from 'ssr-service';
import ArticlesList from '../../components/ArticlesList';
import Breadcrumbs from '../../components/Breadcrumbs';
import EntryAnimation from '../../components/EntryAnimation';
import { Title } from '../../components/Layout';
import Loading from '../../components/Loading';
import Widgets from '../../components/Widgets';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { IPPOUserBio } from '../../library/Sportnet';
import styled from '../../theme/styled-components';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import isBrowser from '../../utilities/isBrowser';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { errorDetailSelector, ppoUserSelector } from '../App/selectors';
import { loadArticlesList } from '../Article/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NotFoundPage from '../NotFoundPage';
import Redirect from '../Redirect';
import { loadRedaktorDetail } from './actions';
import { listAuthorArticlesSelector } from './selectors';

const TitleWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(56)};
  margin-bottom: ${rem(56)};
`;

const AuthorArticlesWrapper = styled.div`
  margin-top: ${rem(56)};
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  > img {
    border-radius: 50%;
    height: ${rem(48)};
    ${mb('s')} {
      height: ${rem(80)};
    }
  }
  margin-right: ${rem(16)};
  ${mb('s')} {
    margin-right: ${rem(24)};
  }
  flex-shrink: 0;
`;

const Position = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-size: 90%;
  margin: ${rem(16)} 0 ${rem(16)} 0;
`;

const StyledTitle = styled(Title as any)`
  display: block;
`;

const StyledBreadcrumbs = styled(Breadcrumbs as any)`
  display: none;
  ${mb('m')} {
    display: block;
  }
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(16)} 0;
`;

const BioPerex = styled.div`
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.app.padding}px;
  max-width: ${({ theme }) => theme.app.maxWidth || 1200}px;
`;

const StyledPaginator = styled(Paginator)`
  margin: auto;
`;

type RouterProps = RouteComponentProps<
  {
    sportnetId: string;
    authorUrlName?: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps & ReduxConnectProps,
) => {
  const { sportnetId } = props.params;
  const appSettings = appSettingsSelector(state);
  const appSpaceId = appSettings.CSMAppSpace!;
  return {
    redaktor: ppoUserSelector(state, {
      appSpaceId,
      userId: sportnetId,
    }),
    error: errorDetailSelector('ppoUserByAppSpaceId', {
      appSpaceId,
      sportnetId,
    })(state),
    appSpaceId: appSettings.CSMAppSpace!,
    baseUri: appSettings.baseUri,
    authorArticles: listAuthorArticlesSelector(state),
    isFetchingArticles: !(
      isCommiting(config.LIST_AUTHOR_ARTICLES)(state) === false
    ),
    nextOffset: getListNextOffset(config.LIST_AUTHOR_ARTICLES)(state),
    appSettings: appSettingsSelector(state),
  };
};

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadRedaktorDetail: loadRedaktorDetail.action,
  loadArticlesList,
};

type Props = RouterProps &
  ReduxConnectProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
  },
};

const Redaktor: React.FC<Props> = ({
  redaktor,
  appSpaceId,
  baseUri,
  initializeOrSetListParams,
  loadRedaktorDetail,
  loadArticlesList,
  params: { sportnetId, authorUrlName },
  router,
  location: { search, pathname },
  authorArticles,
  isFetchingArticles,
  nextOffset,
  error,
}) => {
  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG,
  );

  useAsyncData(async () => {
    try {
      await loadRedaktorDetail({
        appSpaceId,
        authorUrlName: authorUrlName || '',
        sportnetId,
      });
    } catch (e: any) {
      if (!isBrowser()) {
        throw e;
      }
    }
  }, [loadRedaktorDetail, appSpaceId, sportnetId]);

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_AUTHOR_ARTICLES,
      params: {
        ...query,
        authorId: sportnetId,
        limit: config.LIST_AUTHOR_ARTICLES_LIMIT,
      },
    });
    await loadArticlesList(config.LIST_AUTHOR_ARTICLES);
  }, [query, sportnetId]);

  const handleChangeOffset = React.useCallback(
    (offset: number) => {
      setQuery({ offset });
    },
    [setQuery],
  );

  if (error && error.identifier === 'REDIRECT') {
    return <Redirect to={error.payload.location} />;
  }

  if (error) {
    return <NotFoundPage />;
  }

  if (!redaktor) {
    return <Loading />;
  }

  const title = formatUserName(redaktor);

  const bio: Partial<IPPOUserBio> =
    (redaktor.bio || []).find((b) => b.org_profile_id === appSpaceId) || {};

  const { value: position } =
    (redaktor.custom || []).find((c) => c.label === 'Pozícia') || {};

  return (
    <EntryAnimation key={`/redaktor/${sportnetId}`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <MaxWidthBox width={config.WIDER_CONTENT_MAX_WIDTH}>
        <StyledBreadcrumbs
          crumbs={[
            {
              label: 'Redakcia',
              url: '/redakcia/',
            },
            {
              label: title,
              url: getAuthorUrl(`${baseUri}/redakcia`, redaktor),
            },
          ]}
        />
        <TitleWrapper>
          {bio.photo && (
            <StyledMediaManagerImage
              picture={bio.photo}
              naturalHeight={80 * 2}
              naturalWidth={80 * 2}
              script="media"
              webp={true}
              loading="lazy"
            />
          )}
          <StyledTitle>{title}</StyledTitle>
          {position && <Position>{position}</Position>}
        </TitleWrapper>
        {bio.perex && <BioPerex>{bio.perex}</BioPerex>}
        {bio.widgets && <Widgets items={bio.widgets} />}
      </MaxWidthBox>
      {isFetchingArticles && <Loading />}
      {!isFetchingArticles && (
        <AuthorArticlesWrapper>
          <ArticlesList
            title={__('Články autora')}
            articles={authorArticles}
            display={'tiles'}
            // sectionsById={sectionsById}
          />
          <PaginatorWrapper>
            <StyledPaginator
              offset={query.offset}
              limit={config.LIST_AUTHOR_ARTICLES_LIMIT}
              total={config.LIST_AUTHOR_ARTICLES_LIMIT + query.offset + 1}
              onChangeOffset={handleChangeOffset}
              loading={isFetchingArticles}
            />
          </PaginatorWrapper>
        </AuthorArticlesWrapper>
      )}
    </EntryAnimation>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Redaktor);
