import Tag from '@sportnet/ui/SmarttagsControl/SmarttagTags/Tag';
import sportnetUiTheme from '@sportnet/ui/Themes/baseTheme';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Smarttag } from '../../api/CmsApi';
import config from '../../config';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import ContentSectionHeader from '../ContentSectionHeader';
import { Link } from 'react-router';

const Wrapper = styled.div<{ withHeader: boolean }>`
  ${({ withHeader }) => (withHeader ? `margin: ${rem(35)} 0 0 0;` : '')}
`;

interface OwnProps {
  tags: Smarttag[];
  withHeader?: boolean;
}

type Props = OwnProps;

const Tags: React.FC<Props> = ({ tags, withHeader = true }) => {
  if (tags.length === 0) {
    return null;
  }

  function renderGroup(item: Smarttag) {
    return item.values.map((value) => (
      <Link
        to={`/tag/${item.key}:${value.key}`}
        key={`${item.key}-${value.key}`}
      >
        <Tag disabled title={item.key} subtitle={value.title || value.key} />
      </Link>
    ));
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper withHeader={withHeader}>
        {withHeader && (
          <ContentSectionHeader>{__('Značky')}</ContentSectionHeader>
        )}
        <ThemeProvider theme={sportnetUiTheme}>
          <>{tags.map(renderGroup)}</>
        </ThemeProvider>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default Tags;
