import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { em, linearGradient, rem, transparentize } from 'polished';
import * as React from 'react';
import { SecondTitle } from '../../components/Layout';
import config from '../../config';
import styled from '../../theme/styled-components';

const ContentWrapper = styled.div<{ showShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)};
  position: relative;
`;

const Subtitle = styled.p`
  font-size: ${em(16)};
  text-align: center;
  line-height: 1.4;
`;

const Shadow = styled.div`
  position: absolute;
  ${({ theme }) =>
    linearGradient({
      colorStops: [
        `${transparentize(1, theme.app.bgColor)} 0%`,
        `${theme.app.bgColor} 100%`,
      ],
    })}
  height: ${rem(100)};
  bottom: 100%;
  left: 0;
  right: 0;
`;

const RelativeMaxWidthBox = styled(MaxWidthBox)`
  position: relative;
`;

interface IOwnProps {
  title: string;
  subtitle: string;
  showShadow?: boolean;
}

type IProps = IOwnProps;

const ForbiddenMessage: React.FC<IProps> = ({
  title,
  subtitle,
  showShadow,
}) => {
  return (
    <RelativeMaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      {showShadow && <Shadow />}
      <ContentWrapper>
        <SecondTitle isCentered>{title}</SecondTitle>
        <Subtitle>{subtitle}</Subtitle>
      </ContentWrapper>
    </RelativeMaxWidthBox>
  );
};

export default ForbiddenMessage;
