import ContactForm from '@sportnet/web-contact-form/containers/App';
import { defaultTheme as contactFormDefaultTheme } from '@sportnet/web-contact-form/theme';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { ThemeProvider } from 'styled-components';
import { RootState } from '../../configureStore';
import Analytics from '../../containers/Analytics';
import Fonts from '../../containers/Fonts';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import { isStandalone } from '../DomainResolver';
import { appSettingsSelector } from '../DomainResolver/selectors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type OwnProps = {
  theme: ITheme;
};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps & RouteComponentProps<void, void>;

const App: React.FC<Props> = ({
  children,
  location: { pathname, hash },
  appSettings,
  theme,
}) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined' && !hash) {
      try {
        window.scroll({ left: 0, top: 0, behavior: 'smooth' });
      } catch (e: any) {
        // fallback for browsers without support for ScrollToOptions
        window.scroll(0, 0);
      }
    }
  }, [pathname, hash]);

  return (
    <Wrapper>
      {children}
      {isStandalone && <Analytics />}
      <Fonts />
      <ThemeProvider
        theme={{
          ...contactFormDefaultTheme,
          openButton: {
            color: '#fff',
            backgroundColor: theme.color.primary,
          },
          color: {
            primary: theme.color.primary,
            backdropColor: '#000',
          },
          button: {
            color: '#fff',
            backgroundColor: theme.color.primary,
          },
        }}
      >
        <ContactForm appSpace={appSettings.CSMAppSpace || ''} />
      </ThemeProvider>
    </Wrapper>
  );
};

export default compose(withTheme, connect(mapStateToProps))(App);
