import * as React from 'react';
import { Content } from '../../components/Layout';
import PreviewTargetArea from '../../components/PreviewTargetArea';
import Footer from '../../containers/Footer';
import Header from '../../containers/Header';

const Page: React.FC = ({ children }) => {
  return (
    <>
      <PreviewTargetArea id="header">
        <Header />
      </PreviewTargetArea>
      <Content>{children}</Content>
      <PreviewTargetArea id="footer">
        <Footer />
      </PreviewTargetArea>
    </>
  );
};

export default Page;
