import { createGlobalStyle } from './styled-components';

export default createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.app.bgColor};
  }
  html {
    font-size: 100%;
    font-family: 'Open Sans', sans-serif;
    scroll-padding-top: 100px;
  }
  * {
    box-sizing: border-box;
    min-width: 0;
  }
`;
