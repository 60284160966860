import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import configureStore from './configureStore';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';
import ConsentPanel from './containers/Analytics/ConsentPanel';

let state = {};
if (typeof window !== 'undefined') {
  state = (window as any).__REDUX_STATE__;
}
const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});

ReactDOM.hydrate(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Routes history={browserHistory} />
      <ConsentPanel />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
