import { getMediaManagerUrl } from '@sportnet/ui/MediaManagerImage';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { SocialShare } from '@sportnet/component-library/Social';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import { Article, Article_Author } from '../../api/CmsApi';
import ArticlesList from '../../components/ArticlesList';
import Author from '../../components/Author';
import ContentMetaInformation from '../../components/ContentMetaInformation';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Perex from '../../components/Perex';
import Tags from '../../components/Tags';
import Widgets from '../../components/Widgets';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import ContentHeader from '../../containers/ContentHeader';
import getArticleUrl from '../../utilities/getArticleUrl';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';

interface OwnProps {
  article: Article;
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  return {
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps & ReduxConnectProps;

class ArticlePreview extends React.PureComponent<Props> {
  renderTop() {
    const { article, appSettings } = this.props;
    if (!article || !article.doctypeExpanded) {
      return null;
    }

    const content = [];
    if (getProp(article, ['doctypeExpanded', 'top', 'showAuthor']) === true) {
      if (getProp(article, ['doctypeExpanded', 'showCreatedBy'])) {
        content.push(`Spracoval: ${getProp(article, ['owner', 'name'])} `);
      }
      const authors = getProp(article, ['authors'], []) as Article_Author[];
      content.push(
        `Autor: ${authors
          .map((author: Article_Author) => author.name)
          .join(', ')}`,
      );
    }

    if (getProp(article, ['doctypeExpanded', 'top', 'showId']) === true) {
      content.push(`ID článku: ${getProp(article, ['_id'])}`);
    }

    const showDate =
      getProp(article, ['doctypeExpanded', 'top', 'showDate']) === true;

    const showSocial = getProp(
      article,
      ['doctypeExpanded', 'top', 'showSocial'],
      false,
    );

    return (
      <>
        {showSocial && (
          <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
            <SocialShare
              variant="color"
              size={24}
              config={getProp(article, ['doctypeExpanded', 'social'], {})!}
              url={getArticleUrl(appSettings.baseUri || '', article)}
              title={article.name}
            />
          </MaxWidthBox>
        )}
        <ContentMetaInformation
          createdDate={showDate ? article.valid_from || null : null}
          modifiedDate={showDate ? article.modified || null : null}
        >
          {content.join(', ')}
        </ContentMetaInformation>
      </>
    );
  }

  renderAuthor() {
    const { article } = this.props;
    if (!article || !article.doctypeExpanded) {
      return null;
    }

    if (
      getProp(article, ['doctypeExpanded', 'bottom', 'showAuthor']) === true
    ) {
      const authors = getProp(article, ['authors'], []) as Article_Author[];
      if (getProp(article, ['doctypeExpanded', 'showCreatedBy'])) {
        return (
          <>
            <Author>
              {__('Spracoval')} {getProp(article, ['owner', 'name']) as string}
            </Author>{' '}
            <Author>
              {__('Autor')}{' '}
              {authors.map((author: Article_Author) => author.name).join(', ')}
            </Author>
          </>
        );
      } else {
        return (
          <Author>
            {authors.map((author: Article_Author) => author.name).join(', ')}
          </Author>
        );
      }
    }

    return null;
  }

  render() {
    const { article, appSettings } = this.props;
    const articleTitlePicture = article.picture
      ? (article.picture as any)
      : null;
    return (
      <EntryAnimation key="preview">
        <Helmet>
          <title>{article.name}</title>
          <meta
            property="og:url"
            content={getArticleUrl(appSettings.baseUri || '', article)}
          />
          <meta property="og:title" content={article.name} />
          <meta property="og:type" content="article" />
          {articleTitlePicture && (
            <meta
              property="og:image"
              content={getMediaManagerUrl(
                articleTitlePicture,
                1000,
                0,
                'resize',
              )}
            />
          )}
          <meta name="description" content={article.perex || ''} />
        </Helmet>
        <ContentHeader title={article.name || ''} />
        {this.renderTop()}

        {getProp(article, ['doctypeExpanded', 'top', 'showSmarttags']) ===
          true && <Tags withHeader={false} tags={article.smarttags || []} />}
        <Perex text={article.perex || null} />
        <Widgets
          items={article.widgets || []}
          context={{ currentArticleId: article._id }}
        />
        {this.renderAuthor()}
        {getProp(
          article,
          ['doctypeExpanded', 'bottom', 'showSocial'],
          false,
        ) === true && (
          <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
            <SocialShare
              variant="color"
              size={24}
              config={getProp(article, ['doctypeExpanded', 'social'], {})!}
              url={getArticleUrl(appSettings.baseUri || '', article)}
              title={article.name}
            />
          </MaxWidthBox>
        )}
        {getProp(article, ['doctypeExpanded', 'bottom', 'showSmarttags']) ===
          true && <Tags tags={article.smarttags || []} />}
        <HorizontalSpacer height={40} />
        <ArticlesList
          title={__('Náhlad článku')}
          articles={[article]}
          display="tiles"
        />
      </EntryAnimation>
    );
  }
}

export default connect(mapStateToProps)(ArticlePreview);
