import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { getProp, objectToParams } from '@sportnet/utilities';
import CmsApi from '../../api/CmsApi';
import CoreApi from '../../api/CoreApi';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { setAuthUser } from '../../pages/App/actions';

export const TOKEN_COOKIE_KEY = 'pages_token';

const queryStringToObject = (params: string) => {
  const result: any = {};
  if (params.length === 0) {
    return result;
  }
  const query = params.substr(1);
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export function getCallbackAuthUrl(appSpace: string) {
  let redirectUri = '';
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    redirectUri = window.location.href;
    redirectUri = redirectUri.split('#')[0];
  }
  const params = {
    client_id: 'pages',
    response_type: 'token',
    redirect_uri: redirectUri,
    appSpace,
    scope: ['profile', 'groups'],
    state: '',
  } as any;
  return `https://my.sportnet.online/auth/authorize?${objectToParams(params)}`;
}

type IOwnProps = {};

const mapDispatchToProps = {
  setAuthResponse: setAuthUser,
};

type IProps = IOwnProps &
  typeof mapDispatchToProps &
  ReduxConnectProps &
  RouteProps;

interface IState {
  initialized: boolean;
}

class Authorization extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      initialized: true,
    };

    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      const response = queryStringToObject(window.location.hash);
      let token = getProp(response, ['access_token']);
      if (token) {
        window.location.hash = '';
      }

      if (!token) {
        token = Cookies.get(TOKEN_COOKIE_KEY);
      }

      if (token) {
        this.state = {
          initialized: false,
        };
        this.verifyToken(token);
      }
    }
  }

  async verifyToken(token: string) {
    try {
      CoreApi.setToken(token);
      const res = await CoreApi.me();
      Cookies.set(TOKEN_COOKIE_KEY, token);
      CmsApi.setToken(token);
      this.props.setAuthResponse({
        accessToken: token,
        user: res,
      });
      this.setState({
        initialized: true,
      });
    } catch (e: any) {
      this.setState({
        initialized: true,
      });
      Cookies.remove(TOKEN_COOKIE_KEY);
    }
  }

  render() {
    const { children } = this.props;
    const { initialized } = this.state;
    if (!initialized) {
      return null;
    }
    return children;
  }
}

export default connect(null, mapDispatchToProps)(Authorization);
