import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities, PreviewId } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('PREVIEW');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadPreview = createAsync<
  { id: PreviewId },
  NormalizedEntities<'previews'>
>('LOAD', async ({ id }, dispatch, getState, { CmsApi }) => {
  const appSpace: string = CSMAppSpaceSelector(getState());
  try {
    const response = await CmsApi.getPublicPreview(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      id,
      {
        expandWidgets: true,
      },
    );
    return normalizeEntities('previews', [response]);
  } catch (e: any) {
    if (e && e.details && e.details.code === 401) {
      throw new UnauthorizedError(e);
    }
    if (e && e.details && e.details.code === 403) {
      throw new ForbiddenError(e);
    }
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});
