import NotFound from '@sportnet/ui/NotFound';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import config from '../../config';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';

const Wrapper = styled.div`
  display: flex;
  min-height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type Props = {
  title: string;
  actionLabel?: string;
  onClickAction?: () => void;
  theme: ITheme;
};

const ContentNotFound: React.FC<Props> = ({
  title,
  actionLabel,
  onClickAction,
  theme,
}) => {
  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>
        <ThemeProvider
          theme={{
            inactiveColor: '#b5b5b5',
            largeBorderRadius: rem(4),
            fontFamily: "Georgia, 'Times New Roman', Times, serif",
            color: {
              primary: theme.color.primary,
            },
            textColor: theme.app.textColor,
          }}
        >
          <NotFound
            title={title}
            actionLabel={actionLabel}
            onClickAction={onClickAction}
          />
        </ThemeProvider>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default withTheme(ContentNotFound);
