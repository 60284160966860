import { AnyAction } from 'redux';
import { getProp } from '@sportnet/utilities';
import { AsyncActionCreators } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Doctype, Preview, Section } from '../../api/CmsApi';
import {
  IArticle,
  IAuthorizationResponse,
  IPrivateContent,
  Writeable,
} from '../../library/App';
import { IPPOUser } from '../../library/Sportnet';
import serializeParameters from '../../utilities/serializeParameters';
import * as actions from './actions';

export type EntitiesState = {
  doctypes: Readonly<{
    [key: string]: Readonly<Doctype>;
  }>;
  articles: Readonly<{
    [key: string]: Readonly<IArticle>;
  }>;
  sections: Readonly<{
    [key: string]: Readonly<Section & IPrivateContent>;
  }>;
  previews: Readonly<{
    [key: string]: Readonly<Preview>;
  }>;
  ppoUsers: Readonly<{
    [key: string]: Readonly<IPPOUser>;
  }>;
};

export const entitiesReducer = (
  state: EntitiesState = {
    articles: {},
    sections: {},
    previews: {},
    ppoUsers: {},
    doctypes: {},
  },
  action: AnyAction,
): EntitiesState => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc: any, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[keyof EntitiesState]>, id) => {
            innerAcc[id] = {
              ...innerAcc[id],
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export interface IDetailInitialState<
  R extends any | undefined = undefined,
  E = any,
> {
  [key: string]: {
    isFetching: boolean;
    error: E;
    data?: R;
  };
}

export const detailReducerFromAction = <D, E>(
  asyncActionCreators: Array<{
    async: AsyncActionCreators<
      { [key: string]: any },
      { data?: D; [key: string]: any },
      E
    >;
  }>,
  ...parameterKeys: string[]
) =>
  reducerWithInitialState<IDetailInitialState<D>>({})
    .cases(
      asyncActionCreators.map((a) => a.async.started),
      (state, params) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: true,
          },
        };
      },
    )
    .cases(
      asyncActionCreators.map((a) => a.async.done),
      (state, { params, result: { data } }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            data,
            isFetching: false,
            error: null,
          },
        };
      },
    )
    .cases(
      asyncActionCreators.map((a) => a.async.failed),
      (state, { params, error }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: false,
            error,
          },
        };
      },
    );

export const authorizationReducer =
  reducerWithInitialState<IAuthorizationResponse | null>(null).case(
    actions.setAuthUser,
    (state, payload) => payload,
  );
