import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import Authorization from './containers/Authorization';
import Page from './containers/Page';
import App from './pages/App';
import ArticleFilter from './pages/ArticleFilter';
import DomainResolver from './pages/DomainResolver';
import Home from './pages/Home';
import Preview from './pages/Preview';
import Search from './pages/Search';
import Tag from './pages/Tag';
import UrlMap from './pages/UrlMap';
import Redakcia from './pages/Redakcia';
import Redaktor from './pages/Redakcia/redaktor';

const Routes: React.FC<any> = (props) => {
  return (
    <Router {...props}>
      <Route component={DomainResolver}>
        <Route component={Authorization}>
          <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route component={Page}>
              <Route path="/filter" component={ArticleFilter} />
              <Route path="/tag/:tag" component={Tag} />
              <Route path="/search" component={Search} />
              <Route path="/previews/:previewId" component={Preview} />
              <Route path="/redakcia" component={Redakcia} />
              <Route
                path="/redakcia/:sportnetId(/:authorUrlName)"
                component={Redaktor}
              />
              <Route path="*" component={UrlMap} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
