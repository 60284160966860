import { getProp } from '@sportnet/utilities';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { SectionPublicDetail } from '../../api/CmsApi';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import {
  IPrivateContent,
  NormalizedEntities,
  Pager,
  SectionId,
} from '../../library/App';
import NotFoundError from '../../NotFoundError';
import { loadPath } from '../../pages/App/actions';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceListArticleProps from '../../utilities/reduceListArticleProps';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('SECTION');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadSection = createAsync<
  { idOrUniqId: string },
  NormalizedEntities<'sections'>
>('LOAD_SECTION', async ({ idOrUniqId }, dispatch, getState, { CmsApi }) => {
  const appSpace = CSMAppSpaceSelector(getState());

  let response: SectionPublicDetail;
  let state: IPrivateContent['state'] = 'FULL';

  try {
    response = await CmsApi.getPublicSectionByIdOrUniqId(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      idOrUniqId,
      {
        expandWidgets: true,
      },
    );
  } catch (e: any) {
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    } else if (e && e.details && e.details.code === 401) {
      const data = getProp(e.details, ['payload', 'data'], null);
      if (data) {
        response = data;
        state = 'UNATHORIZED';
      } else {
        throw new UnauthorizedError(e);
      }
    } else if (e && e.details && e.details.code === 403) {
      const data = getProp(e.details, ['payload', 'data'], null);
      if (data) {
        response = data;
        state = 'FORBIDDEN';
      } else {
        throw new ForbiddenError(e);
      }
    } else {
      throw new InternalServerError(e);
    }
  }

  await dispatch(loadPath.action({ id: response._id! }));

  return normalizeEntities('sections', [
    reduceSectionProps({ ...response, state }),
  ]);
});

export const loadSectionArticles = createAsync<
  SectionId,
  NormalizedEntities<'articles'> & Pager
>(
  'LOAD_SECTION_ARTICLES',
  async (sectionId, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());
    const { articles, limit, nextOffset, offset, total } =
      await CmsApi.getPublicArticlesBySectionId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        sectionId,
        {
          limit: config.LIST_SECTION_ARTICLES_LIMIT,
          offset: 0,
          widgets: ['all'],
        },
      );
    const entities: NormalizedEntities<'articles'> = normalizeEntities(
      'articles',
      articles.map(reduceListArticleProps),
    );
    const res: NormalizedEntities<'articles'> & Pager = {
      ...entities,
      limit: limit || 0,
      offset: offset!,
      total: total!,
      nextOffset,
    };
    return res;
  },
);

// Set current article id
export const setCurrentSectionId = create<SectionId | null>(
  'SET_CURRENT_SECTION_ID',
);
