import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType, RootState } from '../../configureStore';
import AppSettings from '../../library/AppSettings';
import NotFoundError from '../../NotFoundError';

const create = actionCreatorFactory('DOMAIN_RESOLVER');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadAppSettings = createAsync<
  {
    domain: string;
  },
  AppSettings
>(
  'GET_SETTINGS',
  async ({ domain }, dispatch, getState, { DomainsApi, PagesApi }) => {
    try {
      const match = domain.match(/^appspace\.(.+)$/i);
      let baseUri;
      let ppo: string;
      if (match) {
        baseUri = match[0];
        ppo = match[1];
      } else {
        const resolvedDomain = await DomainsApi.getDomainInfo(domain);
        baseUri = resolvedDomain._id;
        ppo = resolvedDomain.ppo;
      }

      const settings = await PagesApi.publicGetSettings(ppo);
      return {
        CSMAppSpace: ppo,
        baseUri: `https://${baseUri}`.replace(/\/$/, ''),
        ...settings,
      } as AppSettings;
    } catch (e: any) {
      throw new NotFoundError(e);
    }
  },
);

export const setAppSettings = create<{ settings: AppSettings }>('SET_SETTINGS');
