import Icon from '@sportnet/ui/Icon';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import * as React from 'react';
import { withTheme } from 'styled-components';
import config from '../../config';
import styled from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';

const InputWrapper = styled.div`
  margin: ${rem(16)} 0 0 0;
  display: flex;
`;

const Input = styled.input`
  flex: 1 0 0;
  color: ${({ theme }) => theme.searchInput.textColor};
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(24)};
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  min-width: 0;
  ::placeholder {
    color: ${({ theme }) => theme.searchInput.placeholderColor};
  }
`;

const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-shrink: 0;
  :active {
    outline: none;
  }
`;

const Separator = styled.hr`
  display: block;
  height: ${rem(1)};
  border: 0;
  border-top: ${rem(1)} solid ${({ theme }) => theme.searchInput.separatorColor};
  margin: ${rem(10)} 0 ${rem(20)} 0;
  padding: 0;
  width: 100%;
`;

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  theme: ITheme;
  inputRef?: React.RefObject<HTMLInputElement>;
}

type Props = OwnProps;

const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  theme,
  placeholder,
  inputRef,
}) => {
  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value;
    onChange(newValue);
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <InputWrapper>
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          ref={inputRef}
        />
        <IconButton
          onClick={() => {
            onChange('');
          }}
        >
          <Icon
            name="close"
            color={theme.searchInput.resetButtonColor}
            size={34}
          />
        </IconButton>
      </InputWrapper>
      <Separator />
    </MaxWidthBox>
  );
};

export default withTheme(SearchInput);
