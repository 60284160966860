import { combineReducers } from 'redux';
import { listReducer } from '@sportnet/redux-list/ducks';
import { SectionTreeType } from './library/App';
import { IPPOUser } from './library/Sportnet';
import { loadPath, loadSectionTree } from './pages/App/actions';
import {
  authorizationReducer,
  detailReducerFromAction,
  entitiesReducer,
} from './pages/App/reducer';
import { loadArticle } from './pages/Article/actions';
import { currentArticleIdReducer } from './pages/Article/reducer';
import {
  appSettingsReducer,
  IAppSettingsState,
} from './pages/DomainResolver/reducer';
import { loadPreview } from './pages/Preview/actions';
import { loadSection } from './pages/Section/actions';
import {
  currentSectionIdReducer,
  sectionArticlesByIdReducer,
} from './pages/Section/reducer';
import { urlDetailByUrlReducer } from './pages/UrlMap/reducer';
import { loadRedaktorDetail } from './pages/Redakcia/actions';

const rootReducer = combineReducers({
  application: combineReducers<{
    settings: IAppSettingsState;
    authorization: ReturnType<typeof authorizationReducer>;
  }>({
    settings: appSettingsReducer,
    authorization: authorizationReducer,
  }),
  entities: entitiesReducer,
  list: listReducer,
  detail: combineReducers({
    articleById: detailReducerFromAction([loadArticle], 'id'),
    sectionById: detailReducerFromAction([loadSection], 'idOrUniqId'),
    pathBySectionId: detailReducerFromAction<
      { path: string[] | number[] },
      any
    >([loadPath], 'id'),
    sectionTreeBySectionIdOrUniqId: detailReducerFromAction<
      { tree: SectionTreeType },
      any
    >([loadSectionTree], 'sectionIdOrUniqId', 'treelevel'),
    previewById: detailReducerFromAction([loadPreview], 'id'),
    ppoUserByAppSpaceId: detailReducerFromAction<IPPOUser, any>(
      [loadRedaktorDetail],
      'appSpaceId',
      'sportnetId',
    ),
  }),
  currentArticleId: currentArticleIdReducer,
  currentSectionId: currentSectionIdReducer,
  sectionArticlesById: sectionArticlesByIdReducer,
  urlDetailByUrl: urlDetailByUrlReducer,
});

export default rootReducer;
