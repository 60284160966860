import { createSelector } from 'reselect';
import { IDetailInitialState } from '../App/reducer';
import { detailTypeSelector, entitiesSelector } from '../App/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';

export const homeSectionUniqIdSelector = createSelector(
  appSettingsSelector,
  appSettings => {
    return appSettings.homepageSectionIdOrUniqId || '';
  },
);

// CONTENT

const homeSectionDomain = createSelector(
  homeSectionUniqIdSelector,
  detailTypeSelector('sectionById'),
  (id, byId) => byId[id] || ({} as Partial<IDetailInitialState['']>),
);

export const homeSectionIsFetchingSelector = createSelector(
  homeSectionDomain,
  domain => !!domain.isFetching,
);

export const homeSectionSelector = createSelector(
  entitiesSelector,
  homeSectionUniqIdSelector,
  (entities, id) => {
    if (id === null) {
      return null;
    }
    if (id in entities.sections) {
      return entities.sections[id];
    }
    return (
      Object.keys(entities.sections)
        .map(k => entities.sections[k])
        .find(section => section.uniqid === id) || null
    );
  },
);
