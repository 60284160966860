import {
  commit,
  CommitError,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import {
  CustomThunkAction,
  ExtraArgumentType,
  RootState,
} from '../../configureStore';
import { IPPOUser } from '../../library/Sportnet';
import NotFoundError from '../../NotFoundError';
import RedirectError from '../../RedirectError';
import getAuthorUrl, { getAuthorUrlName } from '../../utilities/getAuthorUrl';
import { loadPPOUsers } from '../App/actions';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('REDAKCIA');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const loadRedakciaList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const listParameters = getListParameters(listName)(getState());
    const params = {
      appSpaceId: listParameters.appSpaceId || CSMAppSpaceSelector(getState()),
      params: {
        limit: listParameters.limit || 1000,
        offset: listParameters.offset || 0,
        bioActive: listParameters.bioActive || true,
        ids: listParameters.ids || undefined,
      },
    };
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, total, limit, offset, nextOffset } =
              await dispatch(loadPPOUsers.action(params));
            return {
              total: total!,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            if (e.details && e.details.code === 404) {
              throw e;
            }
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadRedaktorDetail = createAsync<
  { appSpaceId: string; sportnetId: string; authorUrlName: string },
  IPPOUser
>(
  'LOAD_DETAIL',
  async ({ appSpaceId, sportnetId, authorUrlName }, dispatch) => {
    const r = await dispatch(
      loadPPOUsers.action({
        appSpaceId,
        params: {
          bioActive: true,
          ids: [sportnetId],
          limit: 1,
          offset: 0,
        },
      }),
    );

    const item = r.original.find(({ _id }) => _id === sportnetId) as IPPOUser;

    if (!item) {
      throw new NotFoundError({
        details: {
          code: 404,
          description: `User with id ${appSpaceId}/${sportnetId} not found`,
          name: 'NOT_FOUND',
        },
      });
    }

    const authorUrlNameShouldBe = getAuthorUrlName(item);

    if (authorUrlName !== authorUrlNameShouldBe) {
      throw new RedirectError({
        location: getAuthorUrl('/redakcia', item),
        permanent: true,
      });
    }

    return item;
  },
);
