// App related config
export default {
  APP_ID: 'pages',
  MAX_WIDTH: 1200,
  CONTENT_MAX_WIDTH: 1200,
  WIDER_CONTENT_MAX_WIDTH: 1200,
  LIST_SIMILAR_ARTICLES: 'SIMILAR_ARTICLES',
  LIST_SIMILAR_ARTICLES_LIMIT: 12,
  LIST_SECTION_ARTICLES: 'SECTION_ARTICLES',
  LIST_SECTION_ARTICLES_LIMIT: 12,
  LIST_SEARCH_RESULTS: 'SEARCH_RESULTS',
  LIST_SEARCH_RESULTS_LIMIT: 28,
  LIST_NOTFOUND_ARTICLES: 'NOTFOUND_ARTICLES',
  LIST_NOTFOUND_ARTICLES_LIMIT: 12,
  LIST_TAG_ARTICLES: 'TAG_ARTICLES',
  LIST_TAG_ARTICLES_LIMIT: 12,
  LIST_FILTER_ARTICLES: 'FILTER_ARTICLES',
  LIST_FILTER_ARTICLES_LIMIT: 28,
  LIST_REDAKCIA: 'LIST_REDAKCIA',
  LIST_AUTHOR_ARTICLES: 'AUTHOR_ARTICLES',
  LIST_AUTHOR_ARTICLES_LIMIT: 16,
  DATE_TIME_FORMAT: 'd.M.yyyy HH:mm',
  CREATED_DATE_FORMAT: 'EEEE, dd.MM.yyyy',
  DEFAULT_CONTENT_DIVIDER: 'default',
  LIST_ARTICLE_TABS_ARTICLES: 'LIST_ARTICLE_TABS_ARTICLES',
  REDAKCIA_URL: '/redakcia',
  LIST_DOCTYPES: 'LIST_DOCTYPES',
};
