import { getProp } from '@sportnet/utilities';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { URL_Map } from '../../api/CmsApi';
import config from '../../config';
import { ExtraArgumentType, RootState } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import RedirectError from '../../RedirectError';
import InternalServerError from '../../InternalServerError';
import {
  ArticleId,
  IArticle,
  IPrivateContent,
  ISection,
  SectionId,
} from '../../library/App';
import NotFoundError from '../../NotFoundError';
import UnauthorizedError from '../../UnauthorizedError';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('URL_MAP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

interface IUrlMapResult {
  entities: {
    articles: {
      [key: string]: IArticle;
    };
    sections: {
      [key: string]: ISection;
    };
  };
  data: {
    urltype: string;
    object_id: ArticleId | SectionId;
    state: IPrivateContent['state'];
  };
}

export const loadByUrl = createAsync<string, IUrlMapResult>(
  'LOAD_BY_URL',
  async (url, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());

    let response: URL_Map;
    let state: IPrivateContent['state'] = 'FULL';

    try {
      response = await CmsApi.getContentByUrl(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        {
          urlPath: url,
          expandObject: 1,
          expandWidgets: true,
        },
      );
    } catch (e: any) {
      if (e && e.details && e.details.code === 404) {
        throw new NotFoundError(e);
      } else if (e && e.details && e.details.code === 401) {
        const data = getProp(e.details, ['payload', 'data'], null);
        if (data) {
          response = data;
          state = 'UNATHORIZED';
        } else {
          throw new UnauthorizedError(e);
        }
      } else if (e && e.details && e.details.code === 403) {
        const data = getProp(e.details, ['payload', 'data'], null);
        if (data) {
          response = data;
          state = 'FORBIDDEN';
        } else {
          throw new ForbiddenError(e);
        }
      } else {
        throw new InternalServerError(e);
      }
    }

    const entities: IUrlMapResult['entities'] = {
      articles: {},
      sections: {},
    };

    if (response.urltype === 'section') {
      const expandedObject = { ...response.expanded_object, state } as ISection;
      entities.sections[response.object_id!] =
        reduceSectionProps(expandedObject);
    } else if (response.urltype === 'article') {
      const expandedObject = { ...response.expanded_object, state } as IArticle;
      entities.articles[response.object_id!] = expandedObject;
    } else if (response.urltype === 'redirect') {
      throw new RedirectError({
        location: response.location,
        permanent: response.permanent,
      });
    }

    return {
      entities,
      data: {
        urltype: response.urltype!,
        object_id: response.object_id!,
        state,
      },
    };
  },
);
