import { urlToFilter } from '@sportnet/content/utilities/utilities';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import useQuery, {
  NumberParam,
  PageParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import Articles from '../../components/Articles';
import ContentNotFound from '../../components/ContentNotFound';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import { Title } from '../../components/Layout';
import Loading from '../../components/Loading';
import Paginator from '../../components/Paginator';
import config from '../../config';
import { RootState } from '../../configureStore';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { loadArticlesList } from './actions';
import { listArticlesSelector } from './selectors';

const StyledTitle = styled(Title as any)`
  margin: ${rem(16)} 0 ${rem(20)} 0;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state: RootState) => ({
  articles: listArticlesSelector(state),
  isFetching: isCommiting(config.LIST_FILTER_ARTICLES)(state) || false,
  total: getListTotal(config.LIST_FILTER_ARTICLES)(state) || 0,
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadArticlesList,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, void>;

const QUERY_CONFIG = {
  parameters: {
    header: StringParam(__('Články')),
    page: PageParam(1),
    limit: NumberParam(config.LIST_FILTER_ARTICLES_LIMIT),
  },
};

const C: React.FC<Props> = ({
  articles,
  isFetching,
  initializeOrSetListParams,
  location: { search, pathname },
  router,
  appSettings,
  loadArticlesList,
  total,
}) => {
  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_CONFIG,
  );

  const filter = urlToFilter(search.slice(1));

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_FILTER_ARTICLES,
      params: {
        filter: JSON.stringify(filter),
        ...query,
      },
    });
    await loadArticlesList();
  }, [query]);

  function handleChangePage(page: number) {
    setQuery({ page });
  }

  const title = query.header;

  const isLoading = isFetching && !articles.length;
  const isNoResults = !isFetching && !articles.length;
  const paginatorTotal = (query.page - 1) * query.limit + total;

  return (
    <EntryAnimation key="filter">
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={`${appSettings.baseUri}${pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
      </Helmet>

      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <StyledTitle isCentered>{title}</StyledTitle>
      </MaxWidthBox>

      {isLoading ? (
        <Loading />
      ) : isNoResults ? (
        <ContentNotFound title={__('Nenašli sa žiadne články')} />
      ) : (
        <>
          <Articles articles={articles} />
          {total > query.limit && (
            <Paginator
              limit={query.limit || 0}
              page={query.page}
              total={paginatorTotal}
              onChangePage={handleChangePage}
              loading={isLoading}
            />
          )}
        </>
      )}
      <HorizontalSpacer height={35} />
    </EntryAnimation>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(C);
