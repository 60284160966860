import ArticleListWidget from '@sportnet/content/view/widgets/ArticleList';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import * as React from 'react';
import { Article as ArticleType } from '../../api/CmsApi';
import config from '../../config';
import styled from '../../theme/styled-components';
import renderLink from '../../utilities/renderLink';
import ContentSectionHeader from '../ContentSectionHeader';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.articleList.bgColor};
  padding: ${rem(30)} 0 ${rem(70)} 0;
`;

const CenteredContentSectionHeader = styled(ContentSectionHeader as any)`
  text-align: center;
  margin-bottom: ${rem(30)};
`;

type OwnProps = {
  title: string;
  articles: ArticleType[];
  display: string;
};

const ArticlesList: React.FC<OwnProps> = ({ articles, title, display }) => {
  if (articles.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <MaxWidthBox width={config.WIDER_CONTENT_MAX_WIDTH}>
        <CenteredContentSectionHeader>{title}</CenteredContentSectionHeader>
        <ArticleListWidget
          renderLink={renderLink}
          data={{
            display,
            articleFilter: [],
            data: {
              articleFilter: {
                articles,
              },
            },
          }}
        />
      </MaxWidthBox>
    </Wrapper>
  );
};

export default ArticlesList;
