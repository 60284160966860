import * as React from 'react';
import styled, { keyframes } from '../../theme/styled-components';

export const SlideDown = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  animation: ${SlideDown} 0.5s ease-in-out;
`;

interface OwnProps {
  key: string;
}

type Props = OwnProps;

class EntryAnimation extends React.PureComponent<Props> {
  render() {
    const { children } = this.props;
    return <Wrapper>{children}</Wrapper>;
  }
}

export default EntryAnimation;
