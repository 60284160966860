import { getMediaManagerUrl } from '@sportnet/ui/MediaManagerImage';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import EntryAnimation from '../../components/EntryAnimation';
import Loading from '../../components/Loading';
import Widgets from '../../components/Widgets';
import { ReduxConnectProps, RootState } from '../../configureStore';
import Page from '../../containers/Page';
import isBrowser from '../../utilities/isBrowser';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NotFound from '../NotFound';
import { loadSection } from '../Section/actions';
import {
  homeSectionIsFetchingSelector,
  homeSectionSelector,
  homeSectionUniqIdSelector,
} from './selectors';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  homeSectionUniqId: homeSectionUniqIdSelector(state),
  section: homeSectionSelector(state),
  isFetching: homeSectionIsFetchingSelector(state),
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  loadHomeSection: loadSection.action,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = OwnProps &
  IMapStateToProps &
  ReduxConnectProps &
  IMapDispatchToProps;

const Home: React.FC<Props> = ({
  section,
  isFetching,
  loadHomeSection,
  homeSectionUniqId,
  appSettings,
}) => {
  useAsyncData(async () => {
    try {
      await loadHomeSection({ idOrUniqId: homeSectionUniqId });
    } catch (e: any) {
      if (!isBrowser()) {
        throw e;
      }
    }
  }, [homeSectionUniqId, loadHomeSection]);

  if (isFetching && section === null) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  if (section === null) {
    return (
      <Page>
        <NotFound />
      </Page>
    );
  }

  const sectionContentPicture = section.picture
    ? (section.picture as any)
    : null;

  return (
    <Page>
      <EntryAnimation key={'home'}>
        <Helmet>
          <title>{section.name}</title>
          <meta property="og:url" content={appSettings.baseUri} />
          <meta property="og:title" content={section.name} />
          <meta property="og:type" content="section" />
          {sectionContentPicture && (
            <meta
              property="og:image"
              content={getMediaManagerUrl(
                sectionContentPicture,
                1000,
                0,
                'resize',
              )}
            />
          )}
        </Helmet>
        <Widgets
          items={section.content || []}
          context={{ currentSectionId: section._id }}
        />
      </EntryAnimation>
    </Page>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
