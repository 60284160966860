import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from '@sportnet/utilities/at';
import config from '../../config';
import { entitiesSelector } from '../App/selectors';

export const listTagArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_TAG_ARTICLES),
  (entities, ids) => (at as any)(entities.articles, ids || []),
);
