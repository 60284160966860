import { SectionPublic } from '../api/CmsApi';

export default function (baseUri: string, section: SectionPublic) {
  if (section.redirecturl) {
    return section.redirecturl;
  }

  if (section.url) {
    return `${baseUri}/${section.url}`;
  }

  return '';
}
