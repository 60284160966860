import { createSelector } from 'reselect';
import { RootState } from '../../configureStore';

const urlDetailByUrlDomain = (state: RootState) => state.urlDetailByUrl;

const currentUrlDetailDomain = (pathname: string) =>
  createSelector(
    urlDetailByUrlDomain,
    byUrl => {
      const item = byUrl[pathname];
      if (item) {
        return item;
      }
      return null;
    },
  );

export const urlDetailIsFetchingSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail !== null ? detail.isFetching : false),
  );

export const urlDetailSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail && detail.data ? detail.data : null),
  );

export const urlErrorSelector = (pathname: string) =>
  createSelector(
    currentUrlDetailDomain(pathname),
    detail => (detail ? detail.error : undefined),
  );
