import * as React from 'react';
import { Link as RouterLink } from 'react-router';

const Link = (props: any) => {
  const { to, children, ...restProps } = props;
  if (to.startsWith('http')) {
    return (
      <a href={to} {...restProps}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink to={to} {...restProps}>
      {children}
    </RouterLink>
  );
};

export default Link;
