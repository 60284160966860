import FooterDefault from '@sportnet/component-library/FooterSfz';
import { ISection } from '@sportnet/component-library/library/Common';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import { useAsyncData } from 'ssr-service';
import { RootState } from '../../../../configureStore';
import { loadSectionTree } from '../../../../pages/App/actions';
import { sectionTreeSelector } from '../../../../pages/App/selectors';
import { footerConfigurationSelector } from '../../../../pages/DomainResolver/selectors';
import renderLink from '../../../../utilities/renderLink';

const TREELEVEL = 2;
const TREELEVEL_EXTRA_NAV = 0;

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  const footerConfiguration = footerConfigurationSelector(state);
  const sectionIdOrUniqId = getProp(
    footerConfiguration,
    ['config', 'sectionIdOrUniqId'],
    '',
  ) as string;
  const extraSectionIdOrUniqId = getProp(
    footerConfiguration,
    ['config', 'extraSectionIdOrUniqId'],
    '',
  ) as string;
  return {
    footerConfiguration,
    sections: sectionTreeSelector({ sectionIdOrUniqId, treelevel: TREELEVEL })(
      state,
    ),
    extraSections: sectionTreeSelector({
      sectionIdOrUniqId: extraSectionIdOrUniqId,
      treelevel: TREELEVEL_EXTRA_NAV,
    })(state),
    sectionIdOrUniqId,
    extraSectionIdOrUniqId,
  };
};

const mapDispatchToProps = {
  loadSections: loadSectionTree.action,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const C: React.FC<Props> = ({
  footerConfiguration,
  sections,
  sectionIdOrUniqId,
  extraSections,
  extraSectionIdOrUniqId,
  loadSections,
}) => {
  useAsyncData(async () => {
    await Promise.all([
      sectionIdOrUniqId &&
        loadSections({ sectionIdOrUniqId, treelevel: TREELEVEL }),
      extraSectionIdOrUniqId &&
        loadSections({
          sectionIdOrUniqId: extraSectionIdOrUniqId,
          treelevel: TREELEVEL_EXTRA_NAV,
        }),
    ]);
  }, [sectionIdOrUniqId, extraSectionIdOrUniqId]);

  return (
    <FooterDefault
      configuration={footerConfiguration.config || {}}
      renderLink={renderLink}
      sections={sections as ISection[]}
      sectionsBottom={extraSections as ISection[]}
    />
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(C);
