import { getProp } from '@sportnet/utilities';
import { css } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';

interface IFontConfig {
  link: string;
  css: ReturnType<typeof css>;
}

const FONT_CONFIG_BY_FONT_NAME: { [key: string]: IFontConfig } = {
  Ubuntu: {
    link: 'https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap',
    css: css`
      font-family: 'Ubuntu', sans-serif;
    `,
  },
  default: {
    link: '',
    css: css`
      font-family: Georgia, 'Times New Roman', Times, serif;
    `,
  },
};

export const getAppFontConfig = (theme: ITheme): IFontConfig => {
  const fontName = getProp(theme, ['app', 'fontName'], '');
  if (fontName in FONT_CONFIG_BY_FONT_NAME) {
    return FONT_CONFIG_BY_FONT_NAME[fontName];
  }
  return FONT_CONFIG_BY_FONT_NAME.default;
};
