import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { rem } from 'polished';
import * as React from 'react';
import config from '../../config';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  margin: ${rem(10)} 0 0 0;
  color: ${({ theme }) => theme.author.textColor};
  text-align: right;
  font-style: italic;
`;

type Props = {};

const Author: React.FC<Props> = ({ children }) => {
  if (!children) {
    return <></>;
  }

  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper>{children}</Wrapper>
    </MaxWidthBox>
  );
};

export default Author;
