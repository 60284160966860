import ContentContext, {
  IContentContext,
} from '@sportnet/content/common/ContentContext';
import { filterToUrl } from '@sportnet/content/utilities/utilities';
import Content from '@sportnet/content/view';
import * as React from 'react';
import { getProp } from '@sportnet/utilities';
import getArticleUrl from '../../utilities/getArticleUrl';
import getSectionUrl from '../../utilities/getSectionUrl';
import Link from '../Link';

type Props = {
  items: Array<{
    type?: string;
  }>;
  context?: IContentContext;
};

const override = {
  privateContentBoundary: () => null,
};

const C: React.FC<Props> = ({ items, context = {} }) => {
  return (
    <ContentContext.Provider value={context}>
      <Content
        items={items}
        renderLink={(link: any) => {
          if (link.type === 'article_detail') {
            return (
              <Link to={getArticleUrl('', link.payload)} {...link.linkProps}>
                {link.linkProps.children}
              </Link>
            );
          } else if (link.type === 'article_filter') {
            const header = link.payload.header;
            const urlSegments = ['/filter?'];
            if (header) {
              urlSegments.push(`header=${header}`);
            }
            const filter = getProp(link, ['payload', 'filter'], []);
            if (filter.length > 0) {
              urlSegments.push(
                filterToUrl(filter.filter((a: any) => a.type !== 'limit')),
              );
            }
            return (
              <Link to={urlSegments.join('&')} {...link.linkProps}>
                {link.linkProps.children}
              </Link>
            );
          } else if (link.type === 'section_detail') {
            return (
              <Link to={getSectionUrl('', link.payload)} {...link.linkProps}>
                {link.linkProps.children}
              </Link>
            );
          }
          return null;
        }}
        override={override}
      />
    </ContentContext.Provider>
  );
};

export default C;
