import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { ArticleId, IPrivateContent, SectionId } from '../../library/App';
import * as actions from './actions';

export type UrlDetailByUrlState = Readonly<{
  [key: string]: Readonly<{
    isFetching: boolean;
    data: Readonly<{
      urltype: string;
      object_id: ArticleId | SectionId;
      state: IPrivateContent['state'];
    }>;
    error: any;
  }>;
}>;

const URL_DETAIL_INITIAL_STATE: UrlDetailByUrlState = {};

export const urlDetailByUrlReducer = reducerWithInitialState(
  URL_DETAIL_INITIAL_STATE,
)
  .case(
    actions.loadByUrl.async.started,
    (state, url): UrlDetailByUrlState => ({
      ...state,
      [url]: {
        ...state[url],
        isFetching: true,
        error: null,
      },
    }),
  )
  .case(
    actions.loadByUrl.async.done,
    (state, { params: url, result: { data } }): UrlDetailByUrlState => ({
      ...state,
      [url]: {
        isFetching: false,
        data,
        error: null,
      },
    }),
  )
  .case(
    actions.loadByUrl.async.failed,
    (state, { params: url, error }): UrlDetailByUrlState => ({
      ...state,
      [url]: {
        ...state[url],
        isFetching: false,
        error,
      },
    }),
  );
