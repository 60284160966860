import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => rem(height)};
`;

interface OwnProps {
  height: number;
}

type Props = OwnProps;

const HorizontalSpacer: React.FC<Props> = ({ height }) => {
  return <Wrapper height={height} />;
};

export default HorizontalSpacer;
