import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import { RootState } from '../../configureStore';
import { footerConfigurationSelector } from '../../pages/DomainResolver/selectors';
import FooterSimple from './wrappers/FooterSimple';
import FooterFutbalsfz from './wrappers/FooterFutbalsfz';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    footerConfiguration: footerConfigurationSelector(state),
  };
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const Footer: React.FC<Props> = ({ footerConfiguration }) => {
  const type = getProp(footerConfiguration, ['type'], '');
  let component: React.ReactChild = <div />;
  switch (type) {
    case 'simple-1':
      component = <FooterSimple />;
      break;
    case 'futbalsfz':
      component = <FooterFutbalsfz />;
      break;
    default:
      break;
  }
  return component;
};

export default compose(connect(mapStateToProps))(Footer);
