import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React, { ComponentProps } from 'react';
import { connect } from 'react-redux';
import { formatUserName } from '@sportnet/utilities';
import { RootState } from '../../configureStore';
import { IPPOUserBio, IPPOUserCustom } from '../../library/Sportnet';
import styled from '../../theme/styled-components';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import { ppoUserSelector } from '../App/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import AuthorComponent from './Author';

const SpacedAuthorComponent = styled(AuthorComponent)`
  margin-bottom: ${rem(48)};
  ${mb('s')} {
    margin-bottom: ${rem(56)};
  }
`;

type IOwnProps = {
  appSpaceId: string;
  userId: string;
  overwrite?: Partial<ComponentProps<typeof AuthorComponent>>;
};

const mapStateToProps = (
  state: RootState,
  { appSpaceId, userId }: IOwnProps,
) => {
  return {
    author: ppoUserSelector(state, { appSpaceId, userId }),
    appSettings: appSettingsSelector(state),
  };
};

const mapDispatchToProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const getOverwrittenValue = (over: any | undefined, value: any) => {
  if (over !== undefined) {
    return over;
  }
  return value;
};

const Author: React.FC<IProps> = ({
  author,
  appSpaceId,
  overwrite,
  appSettings,
}) => {
  const bio: Partial<IPPOUserBio> = React.useMemo(() => {
    return (
      (author?.bio || []).find((b) => b.org_profile_id === appSpaceId) || {}
    );
  }, [author, appSpaceId]);

  const { value: position } = React.useMemo((): Partial<IPPOUserCustom> => {
    return (author?.custom || []).find((c) => c.label === 'Pozícia') || {};
  }, [author]);

  if (!author) {
    return null;
  }

  return (
    <SpacedAuthorComponent
      displayName={getOverwrittenValue(
        overwrite?.displayName,
        formatUserName(author),
      )}
      facebook={getOverwrittenValue(overwrite?.facebook, bio.facebook) || ''}
      instagram={getOverwrittenValue(overwrite?.instagram, bio.instagram) || ''}
      perex={getOverwrittenValue(overwrite?.perex, bio.perex) || ''}
      twitter={getOverwrittenValue(overwrite?.twitter, bio.twitter) || ''}
      photo={getOverwrittenValue(overwrite?.photo, bio.photo) || null}
      authorUrl={getOverwrittenValue(
        overwrite?.authorUrl,
        getAuthorUrl('/redakcia', author),
      )}
      position={getOverwrittenValue(overwrite?.position, position) || ''}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Author);
