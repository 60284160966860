import React from 'react';
import { withRouter } from 'react-router';
import { RouteProps } from '../../library/App';
import isBrowser from '../../utilities/isBrowser';

type IOwnProps = {
  to: string;
};

type IProps = IOwnProps & RouteProps;

const Redirect: React.FC<IProps> = ({ to, router }) => {
  if (isBrowser()) {
    window.location.replace(to);
  }
  return null;
};

export default withRouter(Redirect) as React.FC<IOwnProps>;
