import { rem } from 'polished';
import useQuery, {
  ArrayParam,
  deserializeQuery,
  NumberParam,
  PageParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import {
  getListNextOffset,
  getListTotal,
  isCommiting,
} from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import styled from 'styled-components';
import Articles from '../../components/Articles';
import ContentNotFound from '../../components/ContentNotFound';
import DoctypesTabs from '../../components/DoctypesTabs';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Loading from '../../components/Loading';
import Paginator from '../../components/Paginator';
import SearchInput from '../../components/SearchInput';
import config from '../../config';
import { RootState } from '../../configureStore';
import useDebounce from '../../hooks/useDebounce';
import { ALL_DOCTYPES } from '../../library/AppSettings';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { loadDoctypesList } from '../Article/actions';
import { doctypesSelector } from '../Article/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { loadSearchResultsList } from './actions';
import { listSearchResultsSelector } from './selectors';

const DoctypesTabsWrapper = styled.div`
  margin-bottom: ${rem(16)};
`;

const mapStateToProps = (state: RootState) => ({
  articles: listSearchResultsSelector(state),
  isFetching: isCommiting(config.LIST_SEARCH_RESULTS)(state) || false,
  total: getListTotal(config.LIST_SEARCH_RESULTS)(state) || 0,
  nextOffset: getListNextOffset(config.LIST_SEARCH_RESULTS)(state) || 0,
  appSettings: appSettingsSelector(state),
  doctypes: doctypesSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadSearchResultList: loadSearchResultsList,
  loadDoctypesList,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, {}>;

const QUERY_CONFIG = {
  parameters: {
    page: PageParam(1),
    q: StringParam(''),
    limit: NumberParam(config.LIST_SEARCH_RESULTS_LIMIT),
    doctypes: ArrayParam(StringParam(''), []),
  },
};

const Search: React.FC<Props> = ({
  articles,
  total,
  nextOffset,
  isFetching,
  initializeOrSetListParams,
  loadSearchResultList,
  loadDoctypesList,
  location: { search, pathname },
  router,
  appSettings,
  doctypes,
}) => {
  const [fullTextSearch, setFullTextSearch] = React.useState(
    deserializeQuery(search).q || '',
  );

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { query, setQuery } = useQuery(
    search,
    (newSearch) => {
      router.push({
        pathname,
        search: newSearch,
      });
    },
    QUERY_CONFIG,
  );

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const debouncedValue = useDebounce(fullTextSearch, 750);
  React.useEffect(() => {
    setQuery({ q: debouncedValue, page: 1 });
    // eslint-disable-next-line
  }, [debouncedValue]);

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_DOCTYPES,
      params: {
        appSpace: appSettings.CSMAppSpace,
      },
    });
    await loadDoctypesList();
  }, []);

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_SEARCH_RESULTS,
      params: {
        page: query.page,
        doctypes: query.doctypes,
        limit: query.limit,
        q: query.q,
      },
    });

    if (query.q === '' || query.q.length > 2) {
      await loadSearchResultList();
    }
  }, [query, loadSearchResultList, initializeOrSetListParams]);

  function handleChangePage(page: number) {
    setQuery({ ...query, page });
  }

  const isLoading = isFetching && !articles.length;
  const isNoResults = !isFetching && !articles.length;
  const paginatorTotal = (query.page - 1) * query.limit + total;

  return (
    <EntryAnimation key={'search'}>
      <Helmet>
        <title>{__('Vyhľadávanie')}</title>
        <meta
          property="og:url"
          content={`${appSettings.baseUri}/search${search || ''}`}
        />
        <meta property="og:title" content={__('Vyhľadávanie')} />
        <meta property="og:type" content="article" />
      </Helmet>

      <SearchInput
        value={fullTextSearch}
        onChange={(q) => {
          setFullTextSearch(q);
        }}
        placeholder={__('Zadajte hľadaný výraz...')}
        inputRef={inputRef}
      />
      <DoctypesTabsWrapper>
        <DoctypesTabs
          selectedDoctypes={
            query.doctypes.length > 0 ? query.doctypes : [ALL_DOCTYPES.id]
          }
          doctypes={doctypes}
          onChange={(doctypeId: string) => {
            if (doctypeId !== ALL_DOCTYPES.id) {
              setQuery({ ...query, doctypes: [doctypeId], page: 1 });
            } else {
              setQuery({ ...query, doctypes: [], page: 1 });
            }
          }}
        />
      </DoctypesTabsWrapper>
      {isLoading ? (
        <Loading />
      ) : isNoResults ? (
        <ContentNotFound title={__('Žiadne výsledky')} />
      ) : (
        <>
          <Articles articles={articles} />
          {(total > query.limit || nextOffset !== null) && (
            <Paginator
              limit={query.limit || 0}
              page={query.page}
              total={paginatorTotal}
              onChangePage={handleChangePage}
              loading={isLoading}
            />
          )}
        </>
      )}
      <HorizontalSpacer height={35} />
    </EntryAnimation>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Search);
