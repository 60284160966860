import Loader from '@sportnet/ui/Loader';
import * as React from 'react';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';

const Wrapper = styled.div`
  display: flex;
  min-height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading: React.FC<{ theme: ITheme }> = ({ theme }) => {
  return (
    <Wrapper>
      <Loader size="xl" color={theme.loader.color} />
    </Wrapper>
  );
};

export default withTheme(Loading);
