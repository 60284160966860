import { getMediaManagerUrl } from '@sportnet/ui/MediaManagerImage';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from '@sportnet/utilities';
import ArticlesList from '../../components/ArticlesList';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Loading from '../../components/Loading';
import Widgets from '../../components/Widgets';
import { ReduxConnectProps, RootState } from '../../configureStore';
import ContentHeader from '../../containers/ContentHeader';
import { ArticleId } from '../../library/App';
import getIdFromProps from '../../utilities/getIdFromProps';
import getSectionUrl from '../../utilities/getSectionUrl';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';
import Forbidden from '../Forbidden';
import ForbiddenMessage from '../Forbidden/ForbiddenMessage';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';
import UnauthorizedMessage from '../Unauthorized/UnauthorizedMessage';
import {
  loadSection,
  loadSectionArticles,
  setCurrentSectionId,
} from './actions';
import {
  currentSectionArticlesIsFetchingSelector,
  currentSectionArticlesSelector,
  currentSectionErrorSelector,
  currentSectionIsFetchingSelector,
  currentSectionSelector,
} from './selectors';

interface OwnProps {
  id: ArticleId;
}

type RouteProps = RouteComponentProps<any, any>;

const mapStateToProps = (state: RootState, props: OwnProps & RouteProps) => {
  return {
    section: currentSectionSelector(state),
    error: currentSectionErrorSelector(state),
    isFetching: currentSectionIsFetchingSelector(state),
    sectionArticles: currentSectionArticlesSelector(state),
    sectionArticlesIsFetching: currentSectionArticlesIsFetchingSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouteProps & IMapStateToProps & ReduxConnectProps;

class Section extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const id = getIdFromProps(props);
    dispatch(setCurrentSectionId(id));
    await Promise.all([
      dispatch(loadSection.action({ idOrUniqId: String(id) })),
      dispatch(loadSectionArticles.action(id)),
    ]);
  }

  async componentDidMount() {
    try {
      await Section.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await Section.getInitialProps(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentSectionId(null));
  }

  render() {
    const { section, isFetching, appSettings, sectionArticles, error } =
      this.props;
    if (isFetching && section === null) {
      return <Loading />;
    }
    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }
    if (section === null) {
      return <NotFound />;
    }

    const sectionPicture = section.picture ? (section.picture as any) : null;
    const sectionContentPicture = section.picture
      ? (section.picture as any)
      : null;

    const sectionArticlesTitle = getProp(section, [
      'sectionArticles',
      'header',
    ]);
    const sectionArticlesDisplay = getProp(section, [
      'sectionArticles',
      'display',
    ]);
    const content = section.content || [];
    return (
      <EntryAnimation key={getIdFromProps(this.props).toString()}>
        <Helmet>
          <title>{section.name}</title>
          <meta
            property="og:url"
            content={getSectionUrl(appSettings.baseUri || '', section)}
          />
          <meta property="og:title" content={section.name} />
          <meta property="og:type" content="section" />
          {sectionContentPicture && (
            <meta
              property="og:image"
              content={getMediaManagerUrl(
                sectionContentPicture,
                1000,
                0,
                'resize',
              )}
            />
          )}
        </Helmet>
        <ContentHeader
          picture={
            sectionPicture
              ? getMediaManagerUrl(sectionPicture, 50, 50, 'resize')
              : null
          }
          title={section.name || ''}
        />
        <Widgets items={content} context={{ currentSectionId: section._id }} />
        {section.state === 'UNATHORIZED' && (
          <UnauthorizedMessage
            title={__('Zamknutý obsah')}
            subtitle={__(
              'Obsah, nie je k dispozícii pre verejnosť. Pre pokračovanie v čítaní sa prosím prihláste.',
            )}
            showShadow={content.length > 1}
          />
        )}
        {section.state === 'FORBIDDEN' && (
          <ForbiddenMessage
            title={__('Zamknutý obsah')}
            subtitle={__('Bohužiaľ, na tento obsah nemáte dostatočné práva')}
            showShadow={content.length > 1}
          />
        )}
        <HorizontalSpacer height={35} />
        <ArticlesList
          title={sectionArticlesTitle || __('Ďalšie články v sekcii')}
          articles={sectionArticles}
          display={sectionArticlesDisplay || 'tiles'}
        />
      </EntryAnimation>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Section) as any;
