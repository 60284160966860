import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { em, rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import ArticlesList from '../../components/ArticlesList';
import EntryAnimation from '../../components/EntryAnimation';
import { Title } from '../../components/Layout';
import Loading from '../../components/Loading';
import config from '../../config';
import { RootState } from '../../configureStore';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { loadNotFoundArticlesList } from './actions';
import { listNotFoundArticlesSelector } from './selectors';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: ${rem(16)};
`;

const Subtitle = styled.p`
  font-size: ${em(16)};
  text-align: center;
  line-height: 1.4;
`;

const mapStateToProps = (state: RootState) => ({
  articles: listNotFoundArticlesSelector(state),
  isFetching: isCommiting(config.LIST_NOTFOUND_ARTICLES)(state) || false,
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadArticlesList: loadNotFoundArticlesList,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, {}>;

const NotFound: React.FC<Props> = ({
  articles,
  isFetching,
  initializeOrSetListParams,
  loadArticlesList,
  appSettings,
  location: { pathname },
}) => {
  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_NOTFOUND_ARTICLES,
      params: {
        limit: config.LIST_NOTFOUND_ARTICLES_LIMIT,
      },
    });
    await loadArticlesList();
  }, []);

  if (isFetching && !articles.length) {
    return <Loading />;
  }

  const title = __('Ľutujeme, stránka sa nenašla');
  const subtitle = __(
    'Stránka, ktorú hľadáte, nie je k dispozícii, alebo bola presunutá. Skontrolujte prosím zadanú url, alebo použite vyhľadávanie vpravo hore.',
  );

  return (
    <EntryAnimation key={'not-found'}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={`${appSettings.baseUri}${pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
        <meta name="description" content={subtitle} />
      </Helmet>

      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <ContentWrapper>
          <Title isCentered>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </ContentWrapper>
      </MaxWidthBox>

      {articles.length && (
        <ArticlesList
          title={__('Články, ktoré by vás mohli zaujímať')}
          articles={articles}
          display="tiles"
        />
      )}
    </EntryAnimation>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotFound);
