import { getMediaManagerUrl } from '@sportnet/ui/MediaManagerImage';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import { SocialShare } from '@sportnet/component-library/Social';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp, slugify } from '@sportnet/utilities';
import styled from 'styled-components';
import { Article_Author } from '../../api/CmsApi';
import ArticlesList from '../../components/ArticlesList';
import ArticleTabs from '../../components/ArticleTabs';
import Author from '../../components/Author';
import ContentMetaInformation from '../../components/ContentMetaInformation';
import EntryAnimation from '../../components/EntryAnimation';
import HorizontalSpacer from '../../components/HorizontalSpacer';
import Perex from '../../components/Perex';
import Tags from '../../components/Tags';
import Widgets from '../../components/Widgets';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import ContentHeader from '../../containers/ContentHeader';
import { ArticleId } from '../../library/App';
import getArticleUrl from '../../utilities/getArticleUrl';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import getIdFromProps from '../../utilities/getIdFromProps';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { accessTokenSelector } from '../App/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import Forbidden from '../Forbidden';
import ForbiddenMessage from '../Forbidden/ForbiddenMessage';
import Loading from '../Loading';
import NotFound from '../NotFound';
import Unauthorized from '../Unauthorized';
import UnauthorizedMessage from '../Unauthorized/UnauthorizedMessage';
import {
  loadArticle,
  loadArticleTabsList,
  loadSimilarArticlesList,
  setCurrentArticleId,
} from './actions';
import {
  articleTabsSelector,
  currentArticleErrorSelector,
  currentArticleIsFetchingSelector,
  currentArticleSelector,
  similarArticlesSelector,
} from './selectors';

const AuthorLink = styled(Link)`
  color: inherit;
  :hover {
    text-decoration: underline;
  }
`;

interface OwnProps {
  id: ArticleId;
}

type RouteProps = RouteComponentProps<
  {
    id: string;
  },
  void
>;

const mapStateToProps = (state: RootState, props: OwnProps & RouteProps) => {
  return {
    article: currentArticleSelector(state),
    error: currentArticleErrorSelector(state),
    isFetching: currentArticleIsFetchingSelector(state),
    appSettings: appSettingsSelector(state),
    similarArticles: similarArticlesSelector(state),
    articleTabs: articleTabsSelector(state),
    accessToken: accessTokenSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouteProps & IMapStateToProps & ReduxConnectProps;

class Article extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const id = getIdFromProps(props);
    dispatch(setCurrentArticleId(id));
    await dispatch(
      initializeOrSetListParams.action({
        listName: config.LIST_SIMILAR_ARTICLES,
        params: {
          offset: 0,
          limit: config.LIST_SIMILAR_ARTICLES_LIMIT,
          articleId: id,
        },
      }),
    );
    await dispatch(
      initializeOrSetListParams.action({
        listName: config.LIST_ARTICLE_TABS_ARTICLES,
        params: {
          articleId: id,
        },
      }),
    );
    await Promise.all([
      dispatch(loadArticle.action({ id })),
      dispatch(loadSimilarArticlesList()),
      dispatch(loadArticleTabsList(config.LIST_ARTICLE_TABS_ARTICLES)),
    ]);
  }

  async componentDidMount() {
    try {
      await Article.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      try {
        await Article.getInitialProps(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentArticleId(null));
  }

  renderTop() {
    const { article, appSettings } = this.props;
    if (!article || !article.doctypeExpanded) {
      return null;
    }

    const content = [];
    if (getProp(article, ['doctypeExpanded', 'top', 'showAuthor']) === true) {
      if (getProp(article, ['doctypeExpanded', 'showCreatedBy'])) {
        if (article.owner) {
          content.push(
            <React.Fragment key="spracoval">
              <>Spracoval: </>
              {this.renderAuthorWithLink(article.owner as Article_Author)}
              {', '}
            </React.Fragment>,
          );
        }
      }
      const authors = ((article?.authors as Article_Author[]) || []).reduce(
        (acc, item, index, array) => {
          acc.push(this.renderAuthorWithLink(item));
          if (index < array.length - 1) {
            acc.push(<>, </>);
          }
          return acc;
        },
        [] as React.ReactNode[],
      );
      content.push(
        <React.Fragment key="autori">
          Autor:{' '}
          {authors.map((author, idx) => (
            <React.Fragment key={`autori-${idx}`}>{author}</React.Fragment>
          ))}
        </React.Fragment>,
      );
    }

    if (getProp(article, ['doctypeExpanded', 'top', 'showId']) === true) {
      if (content.length) {
        content.push(<> | </>);
      }
      content.push(`ID článku: ${getProp(article, ['_id'])}`);
    }

    const showDate =
      getProp(article, ['doctypeExpanded', 'top', 'showDate']) === true;

    const showSocial = getProp(
      article,
      ['doctypeExpanded', 'top', 'showSocial'],
      false,
    );

    return (
      <>
        {showSocial && (
          <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
            <SocialShare
              variant="color"
              size={24}
              config={getProp(article, ['doctypeExpanded', 'social'], {})!}
              url={getArticleUrl(appSettings.baseUri || '', article)}
              title={article.name}
            />
          </MaxWidthBox>
        )}
        <ContentMetaInformation
          createdDate={showDate ? article.valid_from || null : null}
          modifiedDate={showDate ? article.modified || null : null}
        >
          {content}
        </ContentMetaInformation>
      </>
    );
  }

  renderAuthorWithLink(author: Article_Author): React.ReactNode {
    const authorUrl = getAuthorUrl(config.REDAKCIA_URL, author);
    if (authorUrl) {
      return (
        <AuthorLink to={authorUrl} key={authorUrl}>
          {author.name?.trim() ?? ''}
        </AuthorLink>
      );
    } else {
      return <>{author.name?.trim() ?? ''}</>;
    }
  }

  renderAuthor() {
    const { article } = this.props;
    if (!article || !article.doctypeExpanded) {
      return null;
    }

    if (
      getProp(article, ['doctypeExpanded', 'bottom', 'showAuthor']) === true
    ) {
      const authors = getProp(article, ['authors'], []) as Article_Author[];
      if (getProp(article, ['doctypeExpanded', 'showCreatedBy'])) {
        return (
          <>
            <Author>
              {__('Spracoval')}{' '}
              {this.renderAuthorWithLink(
                getProp(article, ['owner']) as Article_Author,
              )}
            </Author>{' '}
            <Author>
              {__('Autor')}{' '}
              {authors.map((author: Article_Author, idx) => (
                <React.Fragment key={`autor-${slugify(author.name)}`}>
                  {this.renderAuthorWithLink(author)}
                </React.Fragment>
              ))}
            </Author>
          </>
        );
      } else {
        return (
          <Author>
            {authors.map((author: Article_Author, idx) => (
              <React.Fragment key={`autor-${slugify(author.name)}`}>
                {this.renderAuthorWithLink(author)}
                {idx < authors.length - 1 && <>, </>}
              </React.Fragment>
            ))}
          </Author>
        );
      }
    }

    return null;
  }

  render() {
    const {
      article,
      isFetching,
      appSettings,
      similarArticles,
      error,
      articleTabs,
      accessToken,
    } = this.props;
    if (isFetching && !article) {
      return <Loading />;
    }
    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }
    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }
    if (!article) {
      return <NotFound />;
    }
    const articleTitlePicture = article.picture
      ? (article.picture as any)
      : null;
    const widgets = article.widgets || [];

    const articleMetaTitle =
      article.meta && article.meta.title ? article.meta.title : article.name;
    const articleMetaDesc =
      article.meta && article.meta.description
        ? article.meta.description
        : article.perex;

    return (
      <EntryAnimation key={getIdFromProps(this.props).toString()}>
        <Helmet>
          <title>{articleMetaTitle}</title>
          <meta
            property="og:url"
            content={getArticleUrl(appSettings.baseUri || '', article)}
          />
          <meta property="og:title" content={articleMetaTitle} />
          <meta property="og:type" content="article" />
          {articleTitlePicture && (
            <meta
              property="og:image"
              content={getMediaManagerUrl(
                articleTitlePicture,
                1000,
                0,
                'resize',
              )}
            />
          )}
          {articleMetaDesc && (
            <meta name="description" content={articleMetaDesc} />
          )}
        </Helmet>
        <ContentHeader title={article.name || ''} />
        {this.renderTop()}
        {article._id && articleTabs && articleTabs.length > 0 && (
          <ArticleTabs
            currentArticleId={article._id}
            articleTabs={articleTabs}
          />
        )}
        {getProp(article, ['doctypeExpanded', 'top', 'showSmarttags']) ===
          true && <Tags withHeader={false} tags={article.smarttags || []} />}
        <Perex text={article.perex || null} />
        <Widgets
          items={widgets}
          context={{
            currentArticleId: article._id,
            accessToken: accessToken ?? undefined,
          }}
        />
        {article.state === 'UNATHORIZED' && (
          <UnauthorizedMessage
            title={__('Zamknutý obsah')}
            subtitle={__(
              'Obsah, nie je k dispozícii pre verejnosť. Pre pokračovanie v čítaní sa prosím prihláste.',
            )}
            showShadow={widgets.length > 1}
          />
        )}
        {article.state === 'FORBIDDEN' && (
          <ForbiddenMessage
            title={__('Zamknutý obsah')}
            subtitle={__('Bohužiaľ, na tento obsah nemáte dostatočné práva')}
            showShadow={widgets.length > 1}
          />
        )}
        {this.renderAuthor()}
        {getProp(
          article,
          ['doctypeExpanded', 'bottom', 'showSocial'],
          false,
        ) === true && (
          <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
            <SocialShare
              variant="color"
              size={24}
              config={getProp(article, ['doctypeExpanded', 'social'], {})!}
              url={getArticleUrl(appSettings.baseUri || '', article)}
              title={article.name}
            />
          </MaxWidthBox>
        )}
        {getProp(article, ['doctypeExpanded', 'bottom', 'showSmarttags']) ===
          true && <Tags tags={article.smarttags || []} />}
        <HorizontalSpacer height={40} />
        <ArticlesList
          title={__('Mohlo by vás zaujímať')}
          articles={similarArticles}
          display="tiles"
        />
      </EntryAnimation>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Article) as any;
