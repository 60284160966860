import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../library/App';
import styled from '../../theme/styled-components';
import renderLink from '../../utilities/renderLink';

type IOwnProps = { currentArticleId: number; articleTabs: IArticle[] };

const linkClass = 'content-articleTabs-link';
const linkClassActive = 'widget-sectionlist-link-active';
const sectionNameClass = 'widget-sectionlist-sectionName';

const WrapperTab = styled.div`
  margin: 0 auto;
  padding: 0 ${rem(15)};
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  border-bottom: ${rem(1)} solid #7e7e7f;
  width: 100%;
  padding: 0;
  font-size: ${rem(14)};
  > :first-child {
    margin-left: 0px;
  }
  ${mb('s')} {
    padding: 0 ${rem(16)} 0 ${rem(16)};
  }

  .${linkClass} {
    display: block;
    padding: ${rem(10)} ${rem(20)} ${rem(9)} ${rem(20)};
    border: ${rem(1)} solid #7e7e7f;
    margin-bottom: ${rem(-1)};
    background: #f5f5f5;
    font-weight: 100;
    color: ${({ theme }) => theme.color.base};
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    text-align: center;
    text-decoration: none;
    ${mb('s')} {
      display: inline-block;
      border-top-left-radius: ${rem(5.3)};
      border-top-right-radius: ${rem(5.3)};
      margin-left: ${rem(-1)};
    }
  }

  .${linkClassActive} {
    background: #fff;
    margin-bottom: none;
    color: #000;
    ${mb('s')} {
      border-bottom: ${rem(1)} solid #fff;
    }
  }
`;

type IProps = IOwnProps;

const ArticleTabs: React.FC<IProps> = ({ currentArticleId, articleTabs }) => {
  if (articleTabs.length <= 0) {
    return null;
  }

  return (
    <WrapperTab className="content-articletabs-tabs">
      {articleTabs.map((a: IArticle) => {
        const isActive = currentArticleId === a._id;
        return (
          <React.Fragment key={a._id}>
            {renderLink({
              type: 'article_detail',
              payload: a,
              linkProps: {
                className: `${linkClass} ${isActive && linkClassActive}`,
                children: (
                  <span
                    className={`${sectionNameClass}${
                      isActive ? ' active' : ''
                    }`}
                  >
                    {a.name}
                  </span>
                ),
              },
            })}
          </React.Fragment>
        );
      })}
    </WrapperTab>
  );
};

export default React.memo(ArticleTabs);
